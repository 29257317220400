import { useEffect } from 'react'

export const useSetSelectedColumns = ({
  selectedTab,
  setSelectedColumns,
  selectedAccountsColumns,
  setAvailableColumns,
  accountsColumns,
  selectedMediaColumns,
  mediaColumns
}) => {
  useEffect(() => {
    if (selectedTab === 'accounts') {
      setSelectedColumns([...selectedAccountsColumns])
      setAvailableColumns(accountsColumns)
    } else {
      setSelectedColumns(selectedMediaColumns)
      setAvailableColumns(mediaColumns)
    }
  }, [selectedTab])
}
