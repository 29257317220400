import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/common/ui/dropdown-menu.jsx'
import { X } from 'lucide-react'

export function DropdownMenuFilter({ text, icon, options, setSelectedValue }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className={
            'cursor-pointer flex items-center gap-1 hover:text-foreground'
          }
        >
          <div>{text}</div>
          <div>{icon}</div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-fit'>
        <DropdownMenuGroup>
          {options.map((option, index) => (
            <DropdownMenuItem
              key={index}
              onClick={() => setSelectedValue(option.value)}
              className='flex items-center gap-2'
            >
              <div>{option.icon}</div>
              <div>{option.label}</div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => setSelectedValue(null)}
          className='flex items-center gap-2'
        >
          <div>
            <X className={'w-4 text-muted-foreground'} strokeWidth={1} />
          </div>
          <div>Reset</div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
