import { getSearches } from '@/redux/Watchtower/actions'
import colors from 'tailwindcss/colors'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'

export const getBadgeStyles = ({ text }) => {
  let bgColor, textColor, borderColor

  switch (text) {
    case 'yes':
      bgColor = '#FDE68ACC'
      textColor = colors.amber[800]
      borderColor = '#FBBF2433'
      break
    case 'no':
      bgColor = '#FFFFFF'
      textColor = colors.black[800]
      borderColor = colors.gray[300]
      break
    default:
      bgColor = '#FFFFFF'
      textColor = '#FFFFFF'
      borderColor = '#FFFFFF'
      break
  }
  return {
    backgroundColor: bgColor,
    color: textColor,
    border: `1px solid ${borderColor}`
  }
}

export const getBadgeText = ({ text }) => {
  switch (text) {
    case 'yes':
      return 'Requires Review'

    case 'no':
      return 'Reviewed'

    default:
      break
  }
}
export const computeCounts = (summary) => {
  if (!summary)
    return {
      all: 0,
      review_required: 0,
      adult: 0,
      impersonations: 0,
      deepfake: 0,
      social: 0,
      others: 0
    }

  const all = Object.entries(summary)
    .filter(
      ([key, val]) =>
        !key.endsWith('in_review') &&
        !key.endsWith('impersonators') &&
        typeof val === 'number'
    )
    .reduce((acc, [key, val]) => acc + val, 0)

  const review_required = Object.keys(summary)
    .filter((key) => key.endsWith('in_review'))
    .reduce((acc, key) => acc + summary[key], 0)

  const getCategoryCount = (category, exclude = []) => {
    return Object.keys(summary)
      .filter(
        (key) =>
          key.includes(category) &&
          !exclude.some((ex) => key.includes(ex)) &&
          !key.includes('deepfake') &&
          !key.endsWith('in_review')
      )
      .reduce((acc, key) => acc + summary[key], 0)
  }

  const getDeepfakesCount = () => {
    return Object.keys(summary)
      .filter((key) => key.includes('deepfake') && !key.endsWith('in_review'))
      .reduce((acc, key) => acc + summary[key], 0)
  }

  const adult = getCategoryCount('adult')
  const social =
    getCategoryCount('instagram', ['impersonators']) +
    getCategoryCount('tiktok', ['impersonators']) +
    getCategoryCount('youtube', ['impersonators'])
  const impersonations = getCategoryCount('impersonators')
  const deepfake = getDeepfakesCount()
  const others = getCategoryCount('common')
  return {
    all,
    review_required,
    adult,
    social,
    impersonations,
    deepfake,
    others
  }
}

export const extractImageName = (text) => {
  if (!text) {
    return 'img'
  }
  return text.slice(-5) + '.jpg'
}

const getOverallScoreLimitFromThreatLevel = (threatLevel) => {
  switch (threatLevel) {
    case 'critical':
      return '90-100'
    case 'high':
      return '80-90'
    case 'moderate':
      return '70-80'
    default:
      return '0-70'
  }
}

export const createPayload = ({
  tab,
  data,
  page,
  filters,
  sort,
  reviewRequired
}) => {
  {
    const { pageKeys } = data[tab]
    const payload = {
      lastKey: pageKeys[page] || null,
      key: tab
    }
    const { threat_level } = filters
    if (tab === 'impersonations') {
      payload.category = 'impersonations'
      if (sort) {
        payload.followers_sorting = sort.followers === 'desc' ? '1' : '-1'
      }
    } else if (tab === 'deepfake') {
      payload.is_deepfake = 1
    } else if (tab !== 'all' && tab !== 'review_required') {
      payload.collection = tab
    }
    if (threat_level && tab !== 'review_required') {
      payload.threat_level =
        tab === 'impersonations'
          ? getOverallScoreLimitFromThreatLevel(threat_level)
          : threat_level
    }

    payload.match = !reviewRequired
    return payload
  }
}
export const fetchSearches = ({
  dispatch,
  tab,
  data,
  page,
  reviewRequired,
  filters,
  sort = null
}) => {
  const payload = createPayload({
    tab,
    data,
    page,
    reviewRequired,
    filters,
    sort
  })
  dispatch(getSearches({ payload }))
}

export function getReviewRequiredPayload() {
  const watchtowerStoreState = watchtowerStore.getState()
  const reviewRequiredData = watchtowerStoreState.reviewRequiredData
  const selectedFilterTab = watchtowerStoreState.selectedFilterTab
  const selectedTab =
    selectedFilterTab === 'results'
      ? watchtowerStoreState.selectedTab
      : watchtowerStoreState.selectedReviewRequiredTab
  const page = reviewRequiredData[selectedTab].currentPage
  return createPayload({
    tab: selectedTab,
    data: reviewRequiredData,
    page,
    filters: { threat_level: null },
    reviewRequired: selectedFilterTab !== 'results'
  })
}
