import TakedownActivity from '@/pages/Takedowns/pages/TakedownActivity/TakedownActivity'
import WatchtowerActivity from '@/pages/Watchtower/pages/WatchtowerActivity/WatchtowerActivity'
import { useSearchParams } from 'react-router-dom'

// Being used to show the TakedownActivity or WatchtowerActivity page based on the query parameter.
export default function InfoPage() {
  const [searchParams] = useSearchParams()
  const takedownFlag = searchParams?.get('takedown')
  return (
    <>
      {takedownFlag === 'true' ? <TakedownActivity /> : <WatchtowerActivity />}
    </>
  )
}
