import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper'

export default function GeneralInfoSection({ userData }) {
  return (
    <div className='grid grid-cols-10'>
      <div className='sm:col-span-5 col-span-full text-base font-medium py-2'>
        <p>General information</p>
      </div>
      <div className='sm:col-span-5 col-span-full text-base font-medium'>
        <div className='text-sm font-normal py-2'>
          <p>Email address</p>
        </div>
        <div className=''>
          <InputWrapper
            placeholder='dave@davehawkins.co'
            className='placeholder:text-muted-foreground placeholder:font-normal placeholder:text-sm text-sm font-normal text-foreground sm:w-96 w-full'
            value={userData?.email_address}
            disabled
          />
        </div>
      </div>
    </div>
  )
}
