import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import DialogWrapper from '@/components/common/Wrappers/Dialog/DialogWrapper'
import { Plus, UploadCloud } from 'lucide-react'

export default function ImageUploadDialog() {
  return (
    <div>
      <DialogWrapper
        trigger={
          <ButtonWrapper
            text='Upload a scan'
            icon={<Plus className='w-4 ' />}
          />
        }
        title={'Upload a scan'}
        description={'We accept .obj and and .stl files up to 4TB in size.'}
        footer={<ButtonWrapper text='Next' />}
      >
        <div className='h-32 border-dashed border-2 rounded-md bg-muted flex justify-center items-center flex-col'>
          <div className='border p-3 rounded-full w-fit bg-gray-200 text-muted-foreground'>
            <UploadCloud />
          </div>
          <div className='text-sm text-muted-foreground pt-2'>
            <p>Drag and drop or browse</p>
          </div>
        </div>
      </DialogWrapper>
    </div>
  )
}
