import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import { PenIcon } from 'lucide-react'
import ImageUploadDialog from './ImageUploadDialog'

export default function Rules() {
  return (
    <div className=' flex justify-between items-center'>
      <div className=''>
        <p className='text-xl font-semibold text-text-foreground'>Rules</p>
      </div>
      <div className=' flex gap-2'>
        <div className=''>
          <ButtonWrapper
            text='Edit rules'
            icon={<PenIcon className='w-4 ' fill='' />}
            variant='outline'
          />
        </div>
        <div className=''>
          <ImageUploadDialog />
        </div>
      </div>
    </div>
  )
}
