import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/common/ui/tabs'
import ImageAssetsSection from './components/AssetsTab/ImageAssetsSection'
import GeneralInfoSection from './components/PreferencesTab/GeneralInfoSection'
import TakedownInfoSection from './components/PreferencesTab/TakedownInfoSection'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import { useEffect, useState } from 'react'
import { getAssets } from '@/redux/Assets/actions'
import { useDispatch } from 'react-redux'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import _ from 'lodash'
import { updateUserData } from '@/redux/User/actions.js'
import profileStore from '@/pages/Profile/profileStore.js'

export default function Profile() {
  const { loading } = useStore(globalStore)
  const { userFormData } = useStore(profileStore)
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('assets')
  const handleTabChange = (value) => {
    setSelectedTab(value)
  }
  const { userData, assets } = useStore(globalStore)
  useEffect(() => {
    if (!assets) {
      dispatch(getAssets())
    }
  }, [dispatch, assets])

  const assetsToShow = _.take(assets, 3)

  const handleSave = () => {
    dispatch(updateUserData({ payload: userFormData }))
  }

  return (
    <div className='w-full max-w-screen-xl md:px-16 px-6'>
      <div className='flex items-center justify-between'>
        <div className='text-xl py-2 font-semibold text-text-foreground'>
          <p>Profile </p>
        </div>
        {selectedTab === 'preferences' && (
          <div className=''>
            <ButtonWrapper
              text='Save'
              disabled={loading}
              onClick={handleSave}
            />
          </div>
        )}
      </div>
      <div className='w-full py-6'>
        <Tabs
          defaultValue='assets'
          className='w-full'
          onValueChange={(value) => {
            handleTabChange(value)
          }}
        >
          <TabsList className='w-full bg-white border-b rounded-none justify-start pb-0'>
            <TabsTrigger
              className='border-b-2 border-white data-[state=active]:border-b-2 data-[state=active]:border-green-500 data-[state=active]:text-green-500 rounded-none'
              value='assets'
            >
              Profile Assets
            </TabsTrigger>
            <TabsTrigger
              className='ml-5 border-b-2 border-white data-[state=active]:border-b-2 data-[state=active]:border-green-500 data-[state=active]:text-green-500 rounded-none mx-4'
              value='preferences'
            >
              Preferences
            </TabsTrigger>
          </TabsList>
          <TabsContent value='assets' className='px-1'>
            <div>
              <ImageAssetsSection assets={assetsToShow} />
            </div>
            {/* Commenting out these sections & will be shown once ready */}
            {/* <div className='py-14'>
              <ScansSection />
            </div>
            <div>
              <AudioAssetsSection />
            </div> */}
          </TabsContent>
          <TabsContent className='flex flex-col gap-14' value='preferences'>
            <div>
              <GeneralInfoSection userData={userData} />
            </div>
            {/* Commenting out these sections & will be shown once ready */}
            {/* <div className='py-14'>
              <TakedownPrefSection />
            </div> */}
            <div>
              <TakedownInfoSection userData={userData} />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}
