import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAssets } from '@/redux/Assets/actions'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'

export const useFetchAssets = () => {
  const dispatch = useDispatch()
  const { auth, assets, setAssets, setShowCompleteProfileSection } =
    useStore(globalStore)

  useEffect(() => {
    if (!assets) {
      dispatch(getAssets({ payload: auth?.session_token }))
    }
  }, [dispatch, auth, assets])

  useEffect(() => {
    if (assets) {
      if (assets?.length >= 3) {
        setShowCompleteProfileSection(false)
      }
      setAssets(assets)
    }
  }, [assets, setAssets, setShowCompleteProfileSection])
}
