import { getSingleSearch } from '@/redux/Watchtower/actions'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'

export const useSetTabFromQuery = (setSelectedTab) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const tabFromQuery = searchParams?.get('selectedTab')
    const reviewRequired = searchParams?.get('reviewRequired')
    if (tabFromQuery) setSelectedTab(tabFromQuery)
    if (!reviewRequired && !tabFromQuery) setSelectedTab('all')
    if (reviewRequired === 'true') setSelectedTab('review_required')
  }, [searchParams, setSelectedTab])
}

export const useFetchSearches = (fetchSearches) => {
  const {
    selectedTab,
    watchtowerData,
    reviewRequiredData,
    setSelectedResults,
    setMarkedForDelete,
    threatLevel,
    setWatchtowerData,
    selectedFilterTab,
    selectedReviewRequiredTab,
    setReviewRequiredData
  } = useStore(watchtowerStore)
  const prevThreatLevel = useRef(threatLevel)

  const data =
    selectedFilterTab === 'results' ? watchtowerData : reviewRequiredData
  const tab =
    selectedFilterTab === 'results' ? selectedTab : selectedReviewRequiredTab
  const setData =
    selectedFilterTab === 'results' ? setWatchtowerData : setReviewRequiredData
  useEffect(() => {
    setSelectedResults([])
    setMarkedForDelete([])
    if (
      !data[tab]?.data ||
      (data[tab]?.appliedFilters?.threat_level !== threatLevel &&
        data[tab].data)
    ) {
      fetchSearches && fetchSearches(data[tab].currentPage, tab)
    }
    setData(tab, {
      appliedFilters: {
        ...data[tab].appliedFilters,
        threat_level: threatLevel
      }
    })
  }, [selectedTab, selectedFilterTab, selectedReviewRequiredTab])

  useEffect(() => {
    if (threatLevel !== prevThreatLevel.current) {
      setWatchtowerData(tab, {
        lastKey: null,
        pageKeys: {},
        currentPage: 1,
        isLastPage: false,
        data: null
      })
      fetchSearches && fetchSearches(1, selectedTab)
    }
    prevThreatLevel.current = threatLevel
  }, [threatLevel])
}

export const useSetCurrentData = ({ id, setSingleSearch, type }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const encodedId = encodeURIComponent(id)
    if (type === 'username_platform') {
      dispatch(getSingleSearch({ payload: `username_platform=${encodedId}` }))
    } else dispatch(getSingleSearch({ payload: `search_results_id=${id}` }))
    return () => {
      setSingleSearch(null)
    }
  }, [dispatch, id])
}
