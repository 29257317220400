export const GET_SEARCHES = 'GET_SEARCHES'
export const GET_SEARCHES_SUCCESS = 'GET_SEARCHES_SUCCESS'
export const GET_SEARCHES_FAILURE = 'GET_SEARCHES_FAILURE'

export const GET_SINGLE_SEARCH = 'GET_SINGLE_SEARCH'
export const GET_SINGLE_SEARCH_SUCCESS = 'GET_SINGLE_SEARCH_SUCCESS'
export const GET_SINGLE_SEARCH_FAILURE = 'GET_SINGLE_SEARCH_FAILURE'

export const GET_SINGLE_IMPERSONATOR_DATA = 'GET_SINGLE_IMPERSONATOR_DATA'
export const GET_SINGLE_IMPERSONATOR_DATA_SUCCESS =
  'GET_SINGLE_IMPERSONATOR_DATA_SUCCESS'
export const GET_SINGLE_IMPERSONATOR_DATA_FAILURE =
  'GET_SINGLE_IMPERSONATOR_DATA_FAILURE'

export const GET_SEARCH_SUMMARY = 'GET_SEARCH_SUMMARY'
export const GET_SEARCH_SUMMARY_SUCCESS = 'GET_SEARCH_SUMMARY_SUCCESS'
export const GET_SEARCH_SUMMARY_FAILURE = 'GET_SEARCH_SUMMARY_FAILURE'

export const GET_SEARCH_SUMMARY_CHART_DATA = 'GET_SEARCH_SUMMARY_CHART_DATA'
export const GET_SEARCH_SUMMARY_CHART_DATA_SUCCESS =
  'GET_SEARCH_SUMMARY_CHART_DATA_SUCCESS'
export const GET_SEARCH_SUMMARY_CHART_DATA_FAILURE =
  'GET_SEARCH_SUMMARY_CHART_DATA_FAILURE'

export const GET_DASHBOARD_DEEPFAKES = 'GET_DASHBOARD_DEEPFAKES'
export const GET_DASHBOARD_DEEPFAKES_SUCCESS = 'GET_DASHBOARD_DEEPFAKES_SUCCESS'
export const GET_DASHBOARD_DEEPFAKES_FAILURE = 'GET_DASHBOARD_DEEPFAKES_FAILURE'

export const GET_DASHBOARD_VIDEOS = 'GET_DASHBOARD_VIDEOS'
export const GET_DASHBOARD_VIDEOS_SUCCESS = 'GET_DASHBOARD_VIDEOS_SUCCESS'
export const GET_DASHBOARD_VIDEOS_FAILURE = 'GET_DASHBOARD_VIDEOS_FAILURE'

export const GET_DASHBOARD_SOCIAL = 'GET_DASHBOARD_SOCIAL'
export const GET_DASHBOARD_SOCIAL_SUCCESS = 'GET_DASHBOARD_SOCIAL_SUCCESS'
export const GET_DASHBOARD_SOCIAL_FAILURE = 'GET_DASHBOARD_SOCIAL_FAILURE'

export const GET_DASHBOARD_IMPERSONATIONS = 'GET_DASHBOARD_IMPERSONATIONS'
export const GET_DASHBOARD_IMPERSONATIONS_SUCCESS =
  'GET_DASHBOARD_IMPERSONATIONS_SUCCESS'
export const GET_DASHBOARD_IMPERSONATIONS_FAILURE =
  'GET_DASHBOARD_IMPERSONATIONS_FAILURE'

export const SET_IS_ME = 'SET_IS_ME'
export const SET_IS_ME_SUCCESS = 'SET_IS_ME_SUCCESS'
export const SET_IS_ME_FAILURE = 'SET_IS_ME_FAILURE'

export const SET_NOT_ME = 'SET_NOT_ME'
export const SET_NOT_ME_SUCCESS = 'SET_NOT_ME_SUCCESS'
export const SET_NOT_ME_FAILURE = 'SET_NOT_ME_FAILURE'

export const SET_IS_NOT_DEEPFAKE = 'SET_IS_NOT_DEEPFAKE'
export const SET_IS_NOT_DEEPFAKE_SUCCESS = 'SET_IS_NOT_DEEPFAKE_SUCCESS'
export const SET_IS_NOT_DEEPFAKE_FAILURE = 'SET_IS_NOT_DEEPFAKE_FAILURE'
