import { getFirstName, getTimeOfDay } from '../../../../utils'

const WelcomeBack = ({ userName }) => {
  const timeOfDay = getTimeOfDay()
  const greetingMap = {
    morning: {
      greeting: 'Good morning',
      icon: '🌅'
    },
    afternoon: {
      greeting: 'Good afternoon',
      icon: '🌤️'
    },
    evening: {
      greeting: 'Good evening',
      icon: '🌃'
    }
  }
  const { greeting, icon } = greetingMap[timeOfDay]
  return (
    <p className='text-xl font-semibold text-text-foreground'>
      {icon} {greeting}, {getFirstName(userName)}.
    </p>
  )
}

export default WelcomeBack
