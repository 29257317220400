import { useEffect } from 'react'
import { fetchDelegates, getuserInfoAction } from '@/redux/User/actions.js'

export const useFetchUserData = ({ auth, dispatch }) => {
  useEffect(() => {
    if (auth?.session_token) {
      dispatch(getuserInfoAction())
      dispatch(fetchDelegates())
    }
  }, [auth?.session_token, dispatch])
}
