import _ from 'lodash'
import { DateTime } from 'luxon'

export const getThisWeekRange = () => {
  const today = DateTime.now()
  const startOfWeek = today.startOf('week')
  const diffInDays = today.diff(startOfWeek, 'days').days
  const daysInRange = Math.floor(Math.max(0, diffInDays))
  return daysInRange
}

export const getThisMonthRange = () => {
  const today = DateTime.now()
  const startOfMonth = today.startOf('month')
  const diffInDays = today.diff(startOfMonth, 'days').days
  const daysInRange = Math.floor(Math.max(0, diffInDays))
  return daysInRange
}

export const getButtonLabel = ({ dateRange, dateValue }) => {
  let label
  switch (dateRange) {
    case 'last_week':
      label = 'Last 7 days'
      break
    case 'last_month':
      label = 'Last 30 days'
      break
    case 'this_week':
      label = 'This week'
      break
    case 'this_month':
      label = 'This month'
      break
    default:
      label = ` ${dateValue?.from?.toLocaleDateString() || ''} -
            ${dateValue?.to?.toLocaleDateString() || ''}`
  }
  return label
}

export const getStatsText = ({ dateRange, dateValue }) => {
  let label
  switch (dateRange) {
    case 'last_week':
      label = 'Statistics for past 7 days.'
      break
    case 'last_month':
      label = 'Statistics for past 30 days'
      break
    case 'this_week':
      label = `Statistics for past ${getThisWeekRange()} days`
      break
    case 'this_month':
      label = `Statistics for past ${getThisMonthRange()} days`
      break
    default:
      if (dateValue?.from && dateValue?.to) {
        const oneDay = 1000 * 60 * 60 * 24
        const daysDiff = Math.floor((dateValue.to - dateValue.from) / oneDay)
        label = `Statistics from ${dateValue.from.toLocaleDateString()} to ${dateValue.to.toLocaleDateString()} (${daysDiff} days)`
      } else {
        label = `No date range selected.`
      }
  }
  return label
}

export const getTimeOfDay = () => {
  const now = new Date()
  const hours = now.getHours()

  if (hours < 12) {
    return 'morning'
  } else if (hours < 18) {
    return 'afternoon'
  } else {
    return 'evening'
  }
}

export const getFirstName = (fullName) => {
  if (!fullName) {
    return ''
  }

  const nameParts = _.split(fullName, ' ')
  return _.get(nameParts, '[0]', '')
}

export const calculateCategoryChange = (data, category) => {
  if (!data || data.length < 2) {
    return null
  }

  const yesterday = data[1]
  const today = data[0]

  if (!yesterday || !today) {
    return null
  }

  const getCategoryTotal = (item, category) => {
    if (category === 'all') {
      return Object.values(item).reduce((total, value) => {
        if (typeof value === 'number') {
          total += value
        }
        return total
      }, 0)
    } else {
      return Object.keys(item).reduce((total, key) => {
        if (key.toLowerCase()?.includes(category.toLowerCase())) {
          total += item[key]
        }
        return total
      }, 0)
    }
  }

  const yesterdayTotal = getCategoryTotal(yesterday, category)
  const todayTotal = getCategoryTotal(today, category)

  if (yesterdayTotal === 0 && todayTotal > 0) {
    return 'Significant increase from yesterday'
  }

  if (yesterdayTotal === 0) {
    return 0
  }

  const change = ((todayTotal - yesterdayTotal) / yesterdayTotal) * 100
  return change.toFixed(2)
}

export const getCount = (obj, key) => {
  if (!key) {
    return 0
  }
  const selectedKey = obj?.find((item) => item.label === key)
  return selectedKey?.value
}
