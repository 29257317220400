import colors from 'tailwindcss/colors'
import { DateTime } from 'luxon'
export const convertTextToLabel = ({ text }) => {
  switch (text) {
    case 'completed':
      return 'Successful'
    case 'processed':
      return 'Processing'
    case 'created':
      return 'Created'
    case 'escalated':
      return 'Escalated'
    default:
      return 'UNDEFINED'
  }
}

export const getBadgeStyles = ({ text }) => {
  let bgColor, textColor, borderColor

  switch (text) {
    case 'completed':
      bgColor = '#BBF7D0CC'
      textColor = colors.green[800]
      borderColor = '#4ADE8033'
      break
    case 'processed':
      bgColor = '#FDE68ACC'
      textColor = colors.amber[800]
      borderColor = '#FBBF2433'
      break
    case 'created':
      bgColor = '#FECDD3CC'
      textColor = '#9F1239'
      borderColor = '#FB718533'
      break
    default:
      bgColor = '#FFFFFF'
      textColor = '#FFFFFF'
      borderColor = '#FFFFFF'
      break
  }
  return {
    backgroundColor: bgColor,
    color: textColor,
    border: `1px solid ${borderColor}`
  }
}
export const socialMediaUrls = {
  instagram: 'https://www.instagram.com/',
  facebook: 'https://www.facebook.com/',
  tiktok: 'https://www.tiktok.com/@'
}
export function getSocialMediaLink(platform, userId) {
  const baseUrl = socialMediaUrls[platform?.toLowerCase()]

  if (!baseUrl) {
    console.error('Unsupported platform')
    return null
  }

  return `${baseUrl}${userId}`
}

export const calculateTakedownData = (data) => {
  const media = {
    // takedowns_completed: data.takedowns_completed || 0,
    adult: Object.keys(data)
      .filter((key) => key.includes('adult'))
      .reduce((sum, key) => sum + data[key], 0),
    deepfakes: Object.keys(data)
      .filter((key) => key.includes('deepfakes') && !key.includes('completed'))
      .reduce((sum, key) => sum + data[key], 0)
  }

  const account = {
    impersonator_takedowns: data.impersonator_takedowns || 0,
    avgTime: (() => {
      const averageTimes = Object.keys(data)
        .filter((key) => key.includes('average_time'))
        .map((key) => data[key])
      return averageTimes.length
        ? (
            averageTimes.reduce((sum, time) => sum + time, 0) /
            averageTimes.length
          ).toFixed(1)
        : '0.0'
    })(),

    completed:
      (data.takedowns_completed || 0) +
      (data.impersonator_takedowns_completed || 0),
    total_takedowns: (data.takedowns || 0) + (data.impersonator_takedowns || 0)
  }

  return { media, account }
}

const adjustDates = (dates) => {
  let { created, takedown, success } = dates

  const createdDate = created ? DateTime.fromISO(created) : null
  const takedownDate = DateTime.fromISO(takedown)
  const successDate = DateTime.fromISO(success)

  let adjustedCreatedDate = createdDate

  if (!createdDate) {
    adjustedCreatedDate = takedownDate.minus({ hours: 6 })
  }

  if (adjustedCreatedDate >= takedownDate) {
    adjustedCreatedDate = takedownDate.minus({ hours: 6 })
  }

  return {
    created: adjustedCreatedDate.toISO(),
    takedown: takedownDate.toISO(),
    success: successDate.toISO()
  }
}

export const getDates = (singleTakedown) => {
  const dates = {
    created:
      singleTakedown?.data?.updates?.match_found ||
      singleTakedown?.data?.updates?.match_found_date,
    takedown: singleTakedown?.data?.updates?.takedown_issued,
    success: singleTakedown?.data?.updates?.takedown_updatedAt
  }

  return adjustDates(dates)
}
