import { useEffect, useState } from 'react'

const CustomBarChart = ({
  chartData,
  color,
  handleBarHover,
  handleBarLeave,
  dateRange
}) => {
  const [gridTemplateColumns, setGridTemplateColumns] = useState('')

  useEffect(() => {
    const columnCount = chartData?.length
    const columns = `repeat(${columnCount}, 1fr)`
    setGridTemplateColumns(columns)
  }, [chartData, dateRange])

  const countArray = chartData.map((item) => item.totalResultsCount)
  const maxValue = Math.max(...countArray)
  return (
    <div className='h-full'>
      <div
        className='h-full grid w-full relative overflow-hidden'
        style={{ gridTemplateColumns }}
      >
        {chartData.length > 0 &&
          chartData.map((data, index) => (
            <div key={index} className='w-full h-full flex items-end'>
              <div
                style={{
                  height: `${
                    data.totalResultsCount === 0
                      ? '15'
                      : (data.totalResultsCount / maxValue) * 100
                  }%`,
                  backgroundColor: `${
                    data.totalResultsCount === 0 ? '#71717a' : color
                  }`
                }}
                onMouseEnter={() => {
                  handleBarHover(data.totalResultsCount, index)
                }}
                onMouseLeave={handleBarLeave}
                className='w-full border-2 border-white rounded-t-sm'
              />
            </div>
          ))}
        <div className='bg-gray-400 h-[2px] border-t-2 absolute w-[100%] bottom-0'></div>
      </div>
    </div>
  )
}

export default CustomBarChart
