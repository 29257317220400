import API from '@/axios/Axios'
import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'

function* fetchStats({ payload }) {
  try {
    const { startDate, endDate } = payload
    const config = {
      params: {
        startDate,
        endDate
      }
    }
    const { data } = yield API.get('/dashboard', config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_STATS_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_STATS_FAILURE,
      payload: error?.message
    })
  }
}

function* dashboardSaga() {
  yield all([takeLatest(actionTypes.GET_STATS, fetchStats)])
}

export default dashboardSaga
