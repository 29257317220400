import { useEffect } from 'react'

export const useSetSelectedColumns = ({
  selectedTab,
  setSelectedColumns,
  initSelectedColumns,
  impersonationsColumns,
  setAvailableColumns,
  initialAvailableColumns
}) => {
  useEffect(() => {
    if (selectedTab === 'impersonations') {
      setSelectedColumns([...initSelectedColumns, ...impersonationsColumns])
      setAvailableColumns([
        { label: 'Result', value: 'result' },
        {
          label: 'Following',
          value: 'following'
        },
        {
          label: 'Followers',
          value: 'followers'
        },
        { label: 'Source', value: 'source' },
        { label: 'Threat Level', value: 'threat_level' }
      ])
    } else {
      setSelectedColumns(initSelectedColumns)
      setAvailableColumns(initialAvailableColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])
}
