import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getDashboardDeepfakes,
  getDashboardImpersonations,
  getDashboardSocial,
  getDashboardVideos,
  getSearchSummary,
  getSearchSummaryChart
} from '@/redux/Watchtower/actions'
import { useStore } from 'zustand'
import { computeCounts } from '@/pages/Watchtower/utils'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import homeStore from '../HomeStore'

export const useFetchSearchSummary = (fetchChartData = true) => {
  const dispatch = useDispatch()
  const { searchSummary, setSearchCounts } = useStore(watchtowerStore)
  const { chartData } = useStore(homeStore)

  useEffect(() => {
    if (!searchSummary) {
      dispatch(getSearchSummary({ payload: { type: 'recent', count: 14 } }))
    }
  }, [dispatch, searchSummary])

  useEffect(() => {
    if (searchSummary) {
      const counts = computeCounts(searchSummary)
      const progressData = [
        { label: 'All', value: counts.all },
        { label: 'Requires review', value: counts.review_required },
        { label: 'Adult', value: counts.adult },
        { label: 'Deepfakes', value: counts.deepfake },
        { label: 'Social', value: counts.social },
        { label: 'Impersonations', value: counts.impersonations },
        { label: 'Others', value: counts.others },
        { label: 'AvgTakedown', value: 0 }
      ]
      if (progressData) {
        setSearchCounts(progressData)
      }
    }
  }, [searchSummary, setSearchCounts])

  useEffect(() => {
    if (!chartData && fetchChartData) {
      dispatch(getSearchSummaryChart({ payload: { type: 'daily', count: 14 } }))
    }
  }, [dispatch, chartData, fetchChartData])
}

export const useFetchTrending = () => {
  const dispatch = useDispatch()
  const {
    dashboardDeepfakes,
    dashboardVideos,
    dashboardImpersonations,
    dashboardSocial
  } = useStore(homeStore)

  useEffect(() => {
    if (!dashboardVideos) {
      dispatch(
        getDashboardVideos({
          payload: {
            match: true,
            collection: 'adult',
            limit: 9,
            type: 'videos'
          }
        })
      )
    }
    if (!dashboardDeepfakes) {
      dispatch(
        getDashboardDeepfakes({
          payload: { match: true, is_deepfake: 1, limit: 9 }
        })
      )
    }

    if (!dashboardSocial) {
      dispatch(
        getDashboardSocial({
          payload: { collection: 'social', limit: 15, match: true }
        })
      )
    }

    if (!dashboardImpersonations) {
      dispatch(
        getDashboardImpersonations({
          payload: { category: 'impersonations', limit: 6 }
        })
      )
    }
  }, [dispatch])
}
