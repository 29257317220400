import { useEffect } from 'react'
import _ from 'lodash'

export const useSetSelectedTab = ({ setSelectedTab, tabsList }) => {
  useEffect(() => {
    let pathName = location.pathname
    if (pathName === '/') {
      setSelectedTab('home')
    } else {
      const split = _.split(pathName, '/', 2)
      tabsList.find((item) => item.value === split[1])
      setSelectedTab(split[1])
    }
  }, [location.pathname, setSelectedTab, tabsList])
}
