import * as actionTypes from './actionTypes'

export const getAssets = () => ({
  type: actionTypes.GET_ASSETS
})

export const uploadAsset = ({ payload }) => ({
  type: actionTypes.UPLOAD_ASSETS,
  payload
})

export const saveAsset = ({ payload }) => ({
  type: actionTypes.SAVE_ASSET,
  payload
})
