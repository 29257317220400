import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'
import API from '@/axios/Axios'
import { toast } from 'sonner'

function* getAssets() {
  try {
    const { data } = yield API.get(`/asset`)
    if (data.success) {
      yield put({
        type: actionTypes.GET_ASSETS_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ASSETS_FAILURE,
      payload: error?.message
    })
  }
}

function* uploadAsset({ payload }) {
  try {
    const { data } = yield API.post(`/asset/upload`, payload)
    if (data.success) {
      yield put({
        type: actionTypes.UPLOAD_ASSETS_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    toast.error('Something went wrong.', {
      description:
        error?.response?.data?.message + '.' ||
        'An error occured while uploading the image.'
    })
    yield put({
      type: actionTypes.UPLOAD_ASSETS_FAILURE,
      payload: error?.message
    })
  }
}

function* saveAsset({ payload }) {
  try {
    const { data } = yield API.post(`/asset/save`, payload)
    if (data.success) {
      yield put({
        type: actionTypes.SAVE_ASSETS_SUCCESS,
        payload: data.data
      })
      yield put({
        type: actionTypes.GET_ASSETS,
        payload: data.data
      })
    }
  } catch (error) {
    toast.error('Something went wrong.', {
      description:
        error?.response?.data?.message + '.' ||
        'An error occured while uploading the image.'
    })
    yield put({
      type: actionTypes.SAVE_ASSETS_FAILURE,
      payload: error?.message
    })
  }
}

function* assetsSaga() {
  yield all([
    takeLatest(actionTypes.GET_ASSETS, getAssets),
    takeLatest(actionTypes.UPLOAD_ASSETS, uploadAsset),
    takeLatest(actionTypes.SAVE_ASSET, saveAsset)
  ])
}

export default assetsSaga
