import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import StatsCard from './StatsCard'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import takedownStore from '@/pages/Takedowns/takedownStore'
import AnalyticsIcon from '@/assets/icons/AnalyticsIcon'
import { Timer } from 'lucide-react'
import { calculateTakedownData } from '@/pages/Takedowns/utils.js'
import { getCount } from '@/pages/Home/utils.js'

export default function StatsNumbers({
  loading,
  globalLoading,
  setSelectedTab
}) {
  const navigate = useNavigate()
  const { searchCounts } = useStore(watchtowerStore)
  const { takedownSummary } = useStore(takedownStore)

  const handleWatchtowerNavigate = (tab) => {
    setSelectedTab(tab)
    navigate(`/watchtower?selectedTab=${tab}&reviewRequired=false`)
  }
  const handleTakedownsNavigate = (tab) => {
    navigate(`/takedown?selectedTab=${tab}`)
  }

  const { media, account } = calculateTakedownData(takedownSummary || {})
  const { adult, deepfakes } = media
  const { completed, total_takedowns, impersonator_takedowns } = account
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4'>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={(
              Number(total_takedowns) +
              Number(getCount(searchCounts, 'All') || 0)
            ).toLocaleString('en-US')}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All results'}
            displayType={'numbers'}
            data={searchCounts}
            name={'all'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: total_takedowns,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: getCount(searchCounts, 'All'),
                onClick: () => handleWatchtowerNavigate('all')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={(
              deepfakes + Number(getCount(searchCounts, 'Deepfakes') || 0)
            ).toLocaleString('en-US')}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All deepfakes'}
            displayType={'numbers'}
            data={searchCounts}
            name={'deepfake'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: deepfakes,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: getCount(searchCounts, 'Deepfakes'),
                onClick: () => handleWatchtowerNavigate('deepfake')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={(
              impersonator_takedowns +
              Number(getCount(searchCounts, 'Impersonations') || 0)
            ).toLocaleString('en-US')}
            icon={<AnalyticsIcon className='w-4' />}
            label={'All impersonations'}
            displayType={'numbers'}
            data={searchCounts}
            name={'impersonators'}
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: impersonator_takedowns,
                onClick: () => handleTakedownsNavigate('accounts')
              },
              {
                label: 'In watchtower',
                value: getCount(searchCounts, 'Impersonations'),
                onClick: () => handleWatchtowerNavigate('impersonations')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={(
              adult + Number(getCount(searchCounts, 'Adult')) || 0
            ).toLocaleString('en-US')}
            label={'All adult content'}
            icon={<AnalyticsIcon className='w-4' />}
            displayType={'numbers'}
            data={searchCounts}
            name='adult'
            dataBreakdown={[
              {
                label: 'Takedowns',
                value: adult,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'In watchtower',
                value: getCount(searchCounts, 'Adult'),
                onClick: () => handleWatchtowerNavigate('adult')
              }
            ]}
          />
        )}
      </div>
      <div className='w-full gap-4 col-span-2 md:col-span-1'>
        {loading || globalLoading ? (
          <SkeletonWrapper width={'100%'} height={'8rem'} number={1} />
        ) : (
          <StatsCard
            key={1}
            value={account.avgTime === '0.0' ? 'N/A' : `${account.avgTime} hrs`}
            label={'Avg. takedown time'}
            icon={<Timer className='w-4' />}
            displayType={'numbers'}
            data={searchCounts}
            name='adult'
            dataBreakdown={[
              {
                label: 'Requests',
                value: total_takedowns,
                onClick: () => handleTakedownsNavigate('media')
              },
              {
                label: 'Completed',
                value: completed,
                onClick: () => handleTakedownsNavigate('media')
              }
            ]}
          />
        )}
      </div>
    </div>
  )
}
