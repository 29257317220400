export const GET_ASSETS = 'GET_ASSETS'
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS'
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE'

export const UPLOAD_ASSETS = 'UPLOAD_ASSETS'
export const UPLOAD_ASSETS_SUCCESS = 'UPLOAD_ASSETS_SUCCESS'
export const UPLOAD_ASSETS_FAILURE = 'UPLOAD_ASSETS_FAILURE'

export const SAVE_ASSET = 'SAVE_ASSET'
export const SAVE_ASSETS_SUCCESS = 'SAVE_ASSETS_SUCCESS'
export const SAVE_ASSETS_FAILURE = 'SAVE_ASSETS_FAILURE'
