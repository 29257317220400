import { Badge } from '@/components/common/ui/badge'
import colors from 'tailwindcss/colors'

export default function EnforceCustomBadge({ text, type }) {
  const getBadgeStyles = () => {
    let bgColor, textColor, borderColor

    switch (type) {
      case 'success':
        bgColor = '#BBF7D0CC'
        textColor = colors.green[800]
        borderColor = '#4ADE8033'
        break
      case 'warn':
        bgColor = '#FDE68ACC'
        textColor = colors.amber[800]
        borderColor = '#FBBF2433'
        break
      case 'error':
        bgColor = '#FECDD3CC'
        textColor = '#9F1239'
        borderColor = '#FB718533'
        break
      default:
        bgColor = '#FFFFFF'
        textColor = '#FFFFFF'
        borderColor = '#FFFFFF'
        break
    }
    return {
      backgroundColor: bgColor,
      color: textColor,
      border: `1px solid ${borderColor}`
    }
  }
  return (
    <Badge
      className='text-sx font-medium flex items-center justify-center w-fit h-[20px]'
      variant='outline'
      style={getBadgeStyles(type)}
    >
      <p className='flex justify-center items-center pb-[1px]'>{text}</p>
    </Badge>
  )
}
