import DropdownTableFilter from '@/components/DropdownTableFilter/DropdownTableFilter'
import takedownStore from '@/pages/Takedowns/takedownStore.js'
import { useStore } from 'zustand'
import { useState } from 'react'
import { useSetSelectedColumns } from '@/pages/Takedowns/hooks/useSetSelectedColumns.js'

const mediaColumns = [
  { label: 'Title', value: 'title' },
  { label: 'Source', value: 'source' },
  { label: 'Status', value: 'status' },
  { label: 'Last modified', value: 'last_modified' }
]
const accountsColumns = [
  { label: 'User', value: 'user_name' },
  { label: 'Source', value: 'source' },
  { label: 'Status', value: 'status' },
  { label: 'Last modified', value: 'last_modified' }
]

const selectedMediaColumns = ['title', 'source', 'status', 'last_modified']
const selectedAccountsColumns = [
  'user_name',
  'platform',
  'status',
  'last_modified'
]

const TakedownFilters = () => {
  const { selectedColumns, selectedTab, setSelectedColumns } =
    useStore(takedownStore)
  const [availableColumns, setAvailableColumns] = useState(mediaColumns)
  useSetSelectedColumns({
    selectedTab,
    setSelectedColumns,
    selectedAccountsColumns,
    setAvailableColumns,
    accountsColumns,
    selectedMediaColumns,
    mediaColumns
  })
  return (
    <div className='flex items-center justify-end pb-2'>
      {/* <div className='flex items-center'>
        <div>
          <InputWrapper
            className='h-10'
            placeholder='Filter sources..'
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            value={searchValue}
          />
        </div>
        <div className='flex px-2 items-center'>
          <div className='pr-2'>
            <FiltersCommandBox
              title={'Status'}
              options={[
                { label: 'Successful', value: 'completed' },
                { label: 'Processing', value: 'processed' },
                { label: 'Escalated', value: 'created' }
              ]}
              selectedValues={statusValue}
              setSelectedValues={setStatusValue}
              icon={<PlusCircle className='mr-2 h-4 w-4' />}
            />
          </div>
          <div className='pr-2'>
            <FiltersCommandBox
              title={'Source'}
              options={sources.map((source) => ({
                label: source,
                value: source
              }))}
              selectedValues={sourceValue}
              setSelectedValues={setSourceValue}
              icon={<PlusCircle className='mr-2 h-4 w-4' />}
            />
          </div>
          <div className='pr-2'>
            <DatePickerWrapper
              trigger={
                <Button variant='outline' className='h-9 border-dashed'>
                  <PlusCircle className='mr-2 h-4 w-4' />
                  Date
                  {dateValue && dateValue.to && dateValue.from && (
                    <>
                      <Separator orientation='vertical' className='mx-2 h-4' />

                      <Badge
                        variant='secondary'
                        className='rounded-sm px-1 font-normal'
                      >
                        {dateValue.from.toLocaleDateString()} -
                        {dateValue.to.toLocaleDateString()}
                      </Badge>
                    </>
                  )}
                </Button>
              }
              date={dateValue}
              setDate={(value) => {
                setDateValue(value)
              }}
            />
          </div>
          {isAnyFilterSelected && (
            <div className=''>
              <ButtonWrapper
                className='border font-medium h-9 text-[14px]'
                text='Reset'
                onClick={clearFilters}
                icon={<X className='h-4 w-4' />}
              />
            </div>
          )}
        </div>
      </div> */}
      <div className='flex items-center justify-end pb-2'>
        <DropdownTableFilter
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
          availableTableItems={availableColumns}
          selectedTab={selectedTab}
          useTabs={false}
        />
      </div>
    </div>
  )
}

export default TakedownFilters
