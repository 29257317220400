import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const homeStore = create(
  devtools((set) => ({
    dateValue: null,
    setDateValue: (value) => set({ dateValue: value }),

    startDate: null,
    setStartDate: (value) => set({ startDate: value }),

    completeData: null,
    setCompleteData: (value) => set({ completeData: value }),

    chartData: null,
    setChartData: (value) => {
      if (value?.length > 0) {
        set({ chartData: value })
      }
    },

    endDate: null,
    setEndDate: (value) => set({ endDate: value }),

    dateRange: null,
    setDateRange: (value) => set({ dateRange: value }),

    deepfakeLoading: false,
    setDeepfakeLoading: (value) => set({ deepfakeLoading: value }),

    socialLoading: false,
    setSocialLoading: (value) => set({ socialLoading: value }),

    videosLoading: false,
    setVideosLoading: (value) => set({ videosLoading: value }),

    impersonationsLoading: false,
    setImpersonationsLoading: (value) => set({ impersonationsLoading: value }),

    dashboardDeepfakes: null,
    setDashboardDeepfakes: (value) => set({ dashboardDeepfakes: value }),

    dashboardSocial: null,
    setDashboardSocial: (value) => set({ dashboardSocial: value }),

    dashboardVideos: null,
    setDashboardVideos: (value) => set({ dashboardVideos: value }),

    dashboardImpersonations: null,
    setDashboardImpersonations: (value) =>
      set({ dashboardImpersonations: value }),

    searchSummaryLoading: false,
    setSearchSummaryLoading: (value) => set({ searchSummaryLoading: value }),

    clear: () =>
      set({
        dateValue: null,
        startDate: null,
        completeData: null,
        chartData: null,
        endDate: null,
        dateRange: null,
        deepfakeLoading: false,
        videosLoading: false,
        dashboardDeepfakes: null,
        dashboardVideos: null,
        dashboardImpersonations: null
      })
  }))
)
export default homeStore
