import SocialIcon from '@/components/common/ui/social-icon'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'

export default function InfoSection({
  domain,
  category,
  page_url,
  selectedTab,
  followers,
  type
}) {
  return (
    <>
      <div className='w-full flex gap-10 py-4 flex-wrap'>
        <div className=''>
          <div className='text-sm text-muted-foreground'>
            <p>Source</p>
          </div>
          <div className='text-sm font-medium text-green-600'>
            <a
              className='flex gap-2 items-center '
              href={page_url}
              target='_blank'
            >
              <div className='flex items-center'>
                {category ? (
                  <TooltipWrapper
                    component={
                      <SocialIcon className='w-4 h-4' platform={category} />
                    }
                    text={
                      category === 'adult'
                        ? 'Sensitive Content'
                        : category.charAt(0).toUpperCase() + category.slice(1)
                    }
                    delayDuration={20}
                  />
                ) : null}
              </div>

              {domain || '--'}
            </a>
          </div>
        </div>
        {selectedTab === 'impersonations' ||
          (type === 'username_platform' && (
            <>
              <div className=''>
                <div className='text-sm text-muted-foreground'>
                  <p>Followers</p>
                </div>
                <div className='py-1 text-sm text-foreground'>
                  <p>{followers?.toLocaleString('en-us') || '--'}</p>
                </div>
              </div>
            </>
          ))}
        <div className=''>
          <div className='text-sm text-muted-foreground'>
            <p>Last Modified</p>
          </div>
          <div className='py-1 text-sm text-foreground'>
            <p>--</p>
          </div>
        </div>
      </div>
    </>
  )
}
