import { Separator } from '@/components/common/ui/separator'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import takedownStore from '@/pages/Takedowns/takedownStore'
import parse from 'html-react-parser'
import { useStore } from 'zustand'

export default function NoticeIssued({ mail, email_address, type }) {
  const { mailsLoading, selectedTab } = useStore(takedownStore)
  const titleMap = {
    domain: 'Takedown Issued',
    registrar: 'Domain registrar takedown notice issued',
    hosting: 'Host takedown notice issued',
    legal_team: 'Legal team escalation triggered'
  }
  const descMap = {
    domain:
      selectedTab === 'media'
        ? 'A takedown notice was sent to the site administrator on your behalf.'
        : ' A takedown notice was sent to the administrator of the account on your behalf.',
    registrar:
      "We've escalated the takedown to the domain registrar after the initial takedown notice was unsuccessful. A notice was sent on your behalf",
    hosting:
      "We've escalated the takedown to the website host after the initial takedown notice was unsuccessful. A notice was sent on your behalf.",
    legal_team:
      'The legal team has been escalated to assist with this takedown as the offending site is not responding to our requests.'
  }
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='px-4'>
        <div className='font-medium text-[16px]'>
          <p>{titleMap[type]}</p>
        </div>
        <div className='text-sm py-1 font-normal text-muted-foreground'>
          <p>{descMap[type]}</p>
        </div>
        <div className='text-sm py-2 font-medium'>
          <p>Details</p>
        </div>
        <Separator />
        <div className=''>
          <div className='text-sm'>
            <div className='pt-3 pb-2 flex w-full'>
              <p>from</p>
              <span className='pl-2 font-medium text-green-600 w-full truncate'>
                {mailsLoading ? (
                  <SkeletonWrapper
                    height={'1.5rem'}
                    width={'100%'}
                    number={1}
                  />
                ) : selectedTab === 'media' ? (
                  email_address || 'Unavailable'
                ) : (
                  'goloti.com'
                )}
              </span>
            </div>
            <div className='bg-gray-100 text-sm py-2 px-2 rounded-md'>
              <div className='rich-text-content overflow-hidden'>
                {mailsLoading ? (
                  <SkeletonWrapper height={'20rem'} width={'100%'} number={1} />
                ) : selectedTab === 'media' ? (
                  mail?.length > 0 ? (
                    <p className={"text-wrap"}>{parse(mail[0]?.body)}</p>
                  ) : (
                    'Unavailable'
                  )
                ) : (
                  'We will continue to monitor the account for any future infringements.'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
