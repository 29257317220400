import { Upload } from 'lucide-react'
import { useEffect, useState } from 'react'
import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper'
import ImageUploadDialog from './ImageUploadDialog'
import { useStore } from 'zustand'
import profileStore from '../../profileStore'
import { useDispatch } from 'react-redux'
import { uploadAsset } from '@/redux/Assets/actions'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import { cn } from '@/lib/utils'

export default function ImageAssetsSection({ assets }) {
  const [open, setOpen] = useState(false)
  const { loading, setLoading, error, faces, success } = useStore(profileStore)
  const dispatch = useDispatch()
  const handleUploadImage = (image) => {
    const supportedFileTypes = ['jpeg', 'jpg', 'png', 'webg', 'heic']
    const filename = image.name
    const fileType = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    )

    if (!supportedFileTypes?.includes(fileType.toLowerCase())) {
      return
    }

    const formData = new FormData()
    formData.append('image', image)
    dispatch(uploadAsset({ payload: formData }))
    setOpen(true)
  }

  useEffect(() => {
    if (error || success) {
      setOpen(false)
    }
  }, [error, success])

  return (
    <div className={'flex flex-col w-full'}>
      <div className='pb-4 pt-1'>
        <p>Image Assets</p>
      </div>
      <div className=' p-2 flex flex-wrap gap-2'>
        {assets?.length < 3 && (
          <div className='pb-4 sm:pb-0 h-80 text-base font-medium w-72'>
            <div className=' h-full flex items-center justify-center flex-col bg-[#F4F4F580] border-dotted border-2 rounded-md relative'>
              <div className='rounded-full p-2 bg-muted'>
                <Upload size={'14px'} />
              </div>
              <div className=' flex py-2'>
                <div className='text-sm font-medium px-1'>
                  <p>Click to upload</p>
                </div>
                <div className='text-sm font-normal text-muted-foreground'>
                  <p>or drag and drop</p>
                </div>
              </div>
              <div className='text-xs font-normal text-muted-foreground'>
                <p>PNG or JPG or JPEG (max 1200x1200)</p>
              </div>
              <InputWrapper
                type='file'
                className='absolute w-full h-full cursor-pointer opacity-0'
                accept='image/*'
                name='image'
                onChange={(event) => {
                  handleUploadImage(event.target.files[0])
                }}
              />
            </div>
          </div>
        )}
        <div
          className={cn('text-base font-medium col-span-7', {
            'col-span-10': assets?.length >= 3
          })}
        >
          <div className='overflow-y-auto flex h-full w-full flex-wrap gap-2'>
            {loading ? (
              <div className='w-full'>
                <SkeletonWrapper
                  className={'w-full h-full flex gap-3 flex-wrap '}
                  number={2}
                  height={'20rem'}
                  width={'18rem'}
                />
              </div>
            ) : (
              assets?.map((item) => (
                <div
                  key={item._id}
                  className='flex items-center justify-evenly h-80'
                >
                  <div className='flex items-center h-full '>
                    <div className='w-72 h-80 overflow-hidden relative rounded-xl flex justify-center items-center border'>
                      <img
                        className={`w-full h-full object-cover`}
                        src={item.url}
                        alt='img'
                        draggable={false}
                        onLoad={() => {
                          setLoading(false)
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <ImageUploadDialog
            open={open}
            setOpen={setOpen}
            loading={loading}
            faces={faces}
          />
        </div>
      </div>
    </div>
  )
}
