import * as React from 'react'
import { ArrowBigUp, ArrowLeftRight, ChevronDown } from 'lucide-react'

import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/common/ui/popover.jsx'
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList
} from '@/components/common/ui/command.jsx'
import Loti from '@/assets/loti.svg'
import LogoutIcon from '@/assets/icons/LogoutIcon.jsx'
import { AlertDialogWrapper } from '@/components/common/Wrappers/AlertDialog/AlertDialog.jsx'
import { clearAllZustandStores } from '@/lib/utils.js'
import { logoutAction } from '@/redux/Auth/actions.js'
import { useDispatch } from 'react-redux'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore.js'
import { CommandBox } from '@/components/SideNav/Delegation/components/CommandBox.jsx'

export function ComboBox() {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('')
  const dispatch = useDispatch()
  const { setDelegate, setParentDelegate, loading, delegates } =
    useStore(globalStore)
  const handleLogout = () => {
    clearAllZustandStores()
    dispatch(logoutAction())
    setDelegate(null)
    setParentDelegate(null)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return (
    <div className={'relative w-full flex items-center'}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <ButtonWrapper
            variant='ghost'
            className='w-full justify-between bg-sidebar px-0'
            onClick={() => setOpen(!open)}
            disabled={loading}
          >
            <div className='flex items-center justify-between w-full px-1 '>
              <div className='w-full gap-1 flex items-center py-[5px] '>
                <div className='px-2 py-2.5'>
                  <img src={Loti} alt='Loti' className='w-5 h-5' />
                </div>
                <div className='font-medium text-sm text-foreground -ml-1'>
                  <p>Loti</p>
                </div>
              </div>
              <div className='px-2'>
                <ChevronDown className='w-4 text-foreground' />
              </div>
            </div>
          </ButtonWrapper>
        </PopoverTrigger>
        <PopoverContent className='w-[15rem] p-0 mx-4'>
          <Command>
            <CommandList>
              <CommandGroup>
                {delegates?.length > 0 && (
                  <CommandItem
                    className={'hover:cursor-pointer h-[2rem]'}
                    value={'switch_workspace'}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? '' : currentValue)
                    }}
                  >
                    <div className={'flex gap-8 items-center justify-between'}>
                      <div className={'flex gap-2 items-center'}>
                        <div>
                          <ArrowLeftRight className={'w-4'} />
                        </div>
                        <div>
                          <p>Switch Workspace</p>
                        </div>
                      </div>
                      <div
                        className={
                          'text-muted-foreground flex items-center gap-1'
                        }
                      >
                        <ArrowBigUp strokeWidth={1} className={'w-3'} />
                        <p className={'text-xs'}>W</p>
                      </div>
                    </div>
                    <CommandBox value={value === 'switch_workspace' && true} />
                  </CommandItem>
                )}
                <CommandItem
                  className={'hover:cursor-pointer h-[2rem]'}
                  value={'logout'}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? '' : currentValue)
                  }}
                >
                  <div className={'w-full flex items-center gap-2 '}>
                    <AlertDialogWrapper
                      trigger={
                        <div
                          className={
                            'flex items-center gap-2 text-sm font-normal w-full'
                          }
                        >
                          <LogoutIcon />
                          <p>Logout</p>
                        </div>
                      }
                      alertCancel={'Cancel'}
                      alertTitle={'Confirm Logout'}
                      alertDescription={
                        'Are you sure you want to log out? Any unsaved changes will be lost.'
                      }
                      alertOk={'Logout'}
                      handleOk={handleLogout}
                    />
                  </div>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}
