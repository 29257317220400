import homeStore from '@/pages/Home/HomeStore'
import * as actionTypes from './actionTypes'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'

const initialState = {
  error: false,
  loading: false,
  assets: false,
  errorMessage: ''
}

const watchtowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEARCHES: {
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(true)
      watchtowerStoreState.setTableLoading(true)
      watchtowerStoreState.setLastKey(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SEARCHES_SUCCESS: {
      const { results, lastKey, key } = action.payload
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(false)
      watchtowerStoreState?.setTableLoading(false)
      if (watchtowerStoreState.selectedFilterTab === 'results') {
        watchtowerStoreState.setWatchtowerData(key, {
          data: results,
          lastKey,
          isLastPage: !lastKey
        })

        const currentPage = watchtowerStoreState.watchtowerData[key].currentPage
        watchtowerStoreState.setPageKey(
          'watchtowerData',
          key,
          currentPage + 1,
          lastKey
        )
      } else {
        watchtowerStoreState.setReviewRequiredData(key, {
          data: results,
          lastKey,
          isLastPage: !lastKey
        })
        const currentPage =
          watchtowerStoreState.reviewRequiredData[key].currentPage
        watchtowerStoreState.setPageKey(
          'reviewRequiredData',
          key,
          currentPage + 1,
          lastKey
        )
      }

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SEARCHES_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      watchtowerStore.getState().setTableLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.GET_SINGLE_SEARCH: {
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(true)
      watchtowerStoreState.setLastKey(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SINGLE_SEARCH_SUCCESS: {
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(false)
      watchtowerStoreState.setSingleSearch(action.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SINGLE_SEARCH_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY: {
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(true)
      watchtowerStoreState.setLastKey(null)
      homeStore.getState().setSearchSummaryLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY_SUCCESS: {
      const watchtowerStoreState = watchtowerStore.getState()
      watchtowerStoreState.setLoading(false)
      watchtowerStoreState.setSearchSummary(action?.payload)
      homeStore.getState().setSearchSummaryLoading(false)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      homeStore.getState().setSearchSummaryLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY_CHART_DATA: {
      watchtowerStore.getState().setLoading(true)
      homeStore.getState().setChartData(null)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY_CHART_DATA_SUCCESS: {
      watchtowerStore.getState().setLoading(false)
      homeStore.getState().setChartData(action?.payload)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_SEARCH_SUMMARY_CHART_DATA_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.GET_DASHBOARD_DEEPFAKES: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setDeepfakeLoading(true)
      homeStoreState.setDashboardDeepfakes(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_DEEPFAKES_SUCCESS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setDeepfakeLoading(false)
      homeStoreState.setDashboardDeepfakes(action?.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_DEEPFAKES_FAILURE: {
      homeStore.getState().setDeepfakeLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }

    case actionTypes.GET_DASHBOARD_VIDEOS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setVideosLoading(true)
      homeStoreState.setDashboardVideos(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_VIDEOS_SUCCESS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setVideosLoading(false)
      homeStoreState.setDashboardVideos(action?.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_VIDEOS_FAILURE: {
      homeStore.getState().setVideosLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }

    case actionTypes.GET_DASHBOARD_SOCIAL: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setSocialLoading(true)
      homeStoreState.setDashboardSocial(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_SOCIAL_SUCCESS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setSocialLoading(false)
      homeStoreState.setDashboardSocial(action?.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_DASHBOARD_SOCIAL_FAILURE: {
      homeStore.getState().setSocialLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }

    case actionTypes.GET_DASHBOARD_IMPERSONATIONS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setImpersonationsLoading(true)
      homeStoreState.setDashboardImpersonations(null)

      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_DASHBOARD_IMPERSONATIONS_SUCCESS: {
      const homeStoreState = homeStore.getState()
      homeStoreState.setImpersonationsLoading(false)
      homeStoreState.setDashboardImpersonations(action?.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_DASHBOARD_IMPERSONATIONS_FAILURE: {
      homeStore.getState().setImpersonationsLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.GET_SINGLE_IMPERSONATOR_DATA: {
      watchtowerStore.getState().setSingleImpersonatorData(null)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SINGLE_IMPERSONATOR_DATA_SUCCESS: {
      watchtowerStore.getState().setSingleImpersonatorData(action.payload)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.GET_SINGLE_IMPERSONATOR_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.SET_IS_ME: {
      watchtowerStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.SET_IS_ME_SUCCESS: {
      watchtowerStore.getState().setLoading(false)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.SET_IS_ME_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.SET_NOT_ME: {
      watchtowerStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.SET_NOT_ME_SUCCESS: {
      watchtowerStore.getState().setLoading(false)

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }

    case actionTypes.SET_NOT_ME_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.SET_IS_NOT_DEEPFAKE: {
      watchtowerStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.SET_IS_NOT_DEEPFAKE_SUCCESS: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.SET_IS_NOT_DEEPFAKE_FAILURE: {
      watchtowerStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    default:
      return state
  }
}

export default watchtowerReducer
