import * as actionTypes from './actionTypes'

export const fetchTakedowns = ({ payload }) => ({
  type: actionTypes.FETCH_TAKEDOWNS,
  payload
})
export const fetchImpersonations = ({ payload }) => ({
  type: actionTypes.FETCH_IMPERSONATIONS,
  payload
})

export const fetchSingleTakedown = ({ payload }) => ({
  type: actionTypes.FETCH_SINGLE_TAKEDOWN,
  payload
})

export const fetchMails = ({ payload }) => ({
  type: actionTypes.FETCH_MAILS,
  payload
})

export const createTakedown = ({ payload }) => ({
  type: actionTypes.CREATE_TAKEDOWN,
  payload
})

export const getTakedownSummary = ({ payload }) => ({
  type: actionTypes.GET_TAKEDOWN_SUMMARY,
  payload
})
