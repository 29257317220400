import { Separator } from '@/components/common/ui/separator'
import takedownStore from '@/pages/Takedowns/takedownStore'
import { useStore } from 'zustand'

export default function Confirmation({ url }) {
  const { selectedTab } = useStore(takedownStore)
  return (
    <div className='px-5'>
      <div className='font-medium text-[16px]'>
        <p>Removal confirmation</p>
      </div>
      <div className='text-sm pt-1 pb-2 font-normal text-muted-foreground'>
        <p>
          {selectedTab === 'media'
            ? 'A Loti robot visited the offending site URL and confirmed that the content has been removed.'
            : 'A Loti robot reviewed the account URL and confirmed that the content has been removed'}
        </p>
      </div>
      <div className='text-sm py-2 font-medium'>
        <p>Details</p>
      </div>
      <Separator />
      <div className='py-4'>
        <div className=''>
          {selectedTab === 'media' && (
            <div className='grid grid-cols-10 sm:gap-4 gap-10'>
              <div className='col-span-2 text-muted-foreground text-sm'>
                <p>URL Visited</p>
              </div>
              <div className='px-2 col-span-8 text-sm'>
                <p className={'text-wrap'}> {url}</p>
              </div>
            </div>
          )}
          <div className='grid grid-cols-10 py-2 sm:gap-4 gap-10'>
            <div className='col-span-2 text-muted-foreground text-sm'>
              <p>Media found</p>
            </div>
            <div className='px-2 col-span-8 text-sm'>
              <p>1</p>
            </div>
          </div>
          <div className='grid grid-cols-10 sm:gap-4 gap-10'>
            <div className='col-span-2 text-muted-foreground text-sm'>
              <p>Takedown result</p>
            </div>
            <div className='px-2 col-span-8 text-sm'>
              <p>Content successfully removed.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
