import ImageContainer from './ImageContainer'

export default function FacialAssets() {
  return (
    <div className='grid grid-cols-3 gap-16'>
      <div className='col-span-1'>
        <div className=' font-semibold'>
          <p>Facial recognition assets</p>
        </div>
        <div className=' text-sm text-muted-foreground'>
          <p>
            Assets uploaded and stored here are used for facial recognition
            across the web.
          </p>
        </div>
      </div>
      <div className='col-span-2 flex gap-4'>
        <ImageContainer />
        <ImageContainer />
      </div>
    </div>
  )
}
