import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import { File } from 'lucide-react'

export default function FaceScansContainer() {
  return (
    <div className='p-4 rounded-md w-full'>
      <div className='flex items-center gap-4'>
        <div className=''>
          <File className='w-6' stroke='gray' />
        </div>
        <div className='flex justify-between w-full'>
          <div>
            <div className='font-semibold text-sm'>
              <p>face-scan.obj</p>
            </div>
            <div className='text-muted-foreground text-xs'>
              <p>4TB, OBJ File</p>
            </div>
          </div>
          <div>
            <ButtonWrapper text='Download' variant='ghost' />
          </div>
        </div>
      </div>
    </div>
  )
}
