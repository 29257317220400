import AudioContainer from './AudioContainer'

export default function AudioAssets() {
  return (
    <div className='grid grid-cols-3 gap-16'>
      <div className='col-span-1'>
        <div className=' font-semibold'>
          <p>Audio assets</p>
        </div>
        <div className=' text-sm text-muted-foreground'>
          <p>
            Assets stored here are used for voice recognition across the web.
          </p>
        </div>
      </div>
      <div className='col-span-2'>
        <div>
          <AudioContainer />
        </div>
        <div className='py-4'>
          <AudioContainer />
        </div>
      </div>
    </div>
  )
}
