import { useState, useEffect } from 'react'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/common/ui/tabs.jsx'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

const FilterTab = ({ results, review_area }) => {
  const { setSelectedTab, selectedTab } = useStore(watchtowerStore)
  const { searchCounts, setSelectedFilterTab } = useStore(watchtowerStore)
  const [previousTab, setPreviousTab] = useState('results')
  const [searchParams, setSearchParams] = useSearchParams()
  const reviewRequired = searchParams?.get('reviewRequired')
  useEffect(() => {
    if (selectedTab !== 'review_required') {
      setPreviousTab(selectedTab)
    }
  }, [selectedTab])

  const handleTabChange = (value) => {
    // if (value === 'review_required') {
    //   setSelectedTab('review_required')
    //   setSearchParams({ reviewRequired: 'true' })
    // } else {
    //   setSelectedTab(previousTab)
    //   setSearchParams({ reviewRequired: 'false' })
    // }
    setSelectedFilterTab(value)
  }
  const requireReviewCount = _.find(searchCounts, { label: 'Requires review' })

  return (
    <Tabs
      defaultValue={reviewRequired === 'true' ? 'review_required' : 'results'}
      className='w-full'
      onValueChange={handleTabChange}
    >
      <TabsList className='bg-background gap-2 border-b w-full flex justify-start py-0 items-end'>
        <TabsTrigger
          value='results'
          className='border-b-2 border-white data-[state=active]:border-b-2 data-[state=active]:border-green-500 data-[state=active]:text-green-500 rounded-none'
        >
          Results
        </TabsTrigger>
        <TabsTrigger
          value='review_required'
          className='border-b-2 border-white data-[state=active]:border-b-2 data-[state=active]:border-green-500 data-[state=active]:text-green-500 rounded-none'
        >
          <div className={'flex items-center gap-1'}>
            <p>Review Area</p>
            {requireReviewCount && (
              <span className='text-xs ml-1 bg-muted-foreground/10 font-normal rounded-full px-3 text-muted-foreground'>
                {requireReviewCount?.value?.toLocaleString('en-us')}
              </span>
            )}
          </div>
        </TabsTrigger>
      </TabsList>
      <TabsContent value='results' className={'w-full'}>
        {results}
      </TabsContent>
      <TabsContent value='review_required' className={'w-full'}>
        {review_area}
      </TabsContent>
    </Tabs>
  )
}

export default FilterTab
