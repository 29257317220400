import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'
import API from '@/axios/Axios'

function* getUserData() {
  try {
    yield put({ type: actionTypes.GET_USER_DATA_REQUESTED })

    const { data } = yield API.get('/user')
    if (data.success) {
      yield put({
        type: actionTypes.GET_USER_DATA_SUCCESS,
        payload: data.data.user
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_USER_DATA_FAILURE,
      payload: error?.response?.data
    })
  }
}
function* fetchDelegates() {
  try {
    const { data } = yield API.get('/user/delegates')
    if (data.success) {
      yield put({
        type: actionTypes.FETCH_DELEGATES_SUCCESS,
        payload: data?.data?.delegates
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_DELEGATES_FAILURE,
      payload: error?.response?.data
    })
  }
}
function* updateUserData({ payload }) {
  try {
    const { data } = yield API.post('/user/edit', payload)
    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_USER_DATA_SUCCESS,
        payload: data?.data?.delegates
      })
      yield put({
        type: actionTypes.GET_USER_DATA,
        payload: data?.data?.delegates
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_USER_DATA_FAILURE,
      payload: error?.response?.data
    })
  }
}

function* userSaga() {
  yield all([
    takeLatest(actionTypes.GET_USER_DATA, getUserData),
    takeLatest(actionTypes.FETCH_DELEGATES, fetchDelegates),
    takeLatest(actionTypes.UPDATE_USER_DATA, updateUserData)
  ])
}

export default userSaga
