import * as actionTypes from './actionTypes'

export const loginAction = ({ payload }) => ({
  type: actionTypes.LOGIN,
  payload
})
export const logoutAction = () => ({
  type: actionTypes.LOGOUT
})
export const crossLogin = ({ payload }) => ({
  type: actionTypes.CROSS_LOGIN,
  payload
})
