import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import EnforceCustomBadge from './EnforceCustomBadge'

export default function EnforceHomeCard({
  img,
  title,
  text,
  badgeType,
  activityPage
}) {
  const getText = () => {
    switch (badgeType) {
      case 'success':
        return 'Compliant'
      case 'error':
        return 'In breach'
      default:
        break
    }
  }
  return (
    <div className='flex justify-between items-center'>
      <div className='flex items-center gap-6'>
        <div className='border overflow-hidden rounded-lg w-40 h-24'>
          <img src={img} alt='' className='w-full h-full' />
        </div>
        <div className='flex flex-col gap-2'>
          <div
            className={`flex gap-3 ${activityPage ? 'flex-col' : 'items-center'}`}
          >
            <div className='font-semibold text-lg'>
              <p>{title}</p>
            </div>
            <div className=''>
              <EnforceCustomBadge text={getText()} type={badgeType} />
            </div>
          </div>
          {!activityPage && (
            <div className='text-sm text-muted-foreground'>
              <p>{text}</p>
            </div>
          )}
        </div>
      </div>
      {!activityPage && (
        <div className=''>
          <ButtonWrapper text='View' variant='outline' />
        </div>
      )}
    </div>
  )
}
