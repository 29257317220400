import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 h-5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        successful: 'border-transparent bg-green-200/60 text-green-600',
        breached: 'border-transparent bg-red-200/60 text-red-600',
        processing: 'border-transparent bg-amber-200/60 text-amber-600',
        warning: 'border-transparent bg-yellow-200/60 text-yellow-600',
        escalated: 'border-transparent bg-rose-200/60 text-rose-600',
        underreview: 'border-transparent bg-orange-200/60 text-orange-600',
        rejected: 'border bg-background text-red-600',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

function Badge({ className, variant, ...props }) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge }
