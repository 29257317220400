import { useEffect, useState } from 'react'
import Error from '../../assets/error.png'
import { TriangleAlert } from 'lucide-react'
import ButtonWrapper from '../common/Wrappers/Button/ButtonWrapper'
import { cn } from '@/lib/utils'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import TooltipWrapper from '../common/Wrappers/Tooltip/TooltipWrapper'
import SocialIcon from '../common/ui/social-icon'

export default function NsfwImageSection({
  hideSensitiveContent,
  imgFallback,
  img,
  intermediateFallbackImg,
  size = 'large',
  handleNavigation,
  id,
  category,
  title,
  customHeight,
  customWidth
}) {
  const [showImage, setShowImage] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [fallbackImageError, setFallbackImageError] = useState(false)
  const [intermediateFallbackImageError, setIntermediateFallbackImageError] =
    useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setShowImage(!hideSensitiveContent)
  }, [hideSensitiveContent])

  const handleImageError = () => {
    setImageError(true)
  }

  const handleIntermediateFallbackImageError = () => {
    setIntermediateFallbackImageError(true)
  }

  const handleFallbackImageError = () => {
    setFallbackImageError(true)
  }

  const handleImageLoad = () => {
    setLoading(false)
  }

  const imageSrc = !imageError
    ? img
    : !intermediateFallbackImageError && intermediateFallbackImg
      ? intermediateFallbackImg
      : !fallbackImageError && imgFallback
        ? imgFallback
        : Error

  function ImageContainer() {
    return (
      <div className='w-full flex justify-center items-center'>
        <ButtonWrapper
          className=''
          icon={<TriangleAlert className='text-white' />}
          variant='translucent'
          size='icon'
          onClick={() => setShowImage((prev) => !prev)}
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={cn(
          'overflow-hidden relative rounded-xl cursor-pointer bg-black/50',
          {
            'w-64 h-64 cursor-default': size === 'large',
            'w-16 h-16': size === 'small',
            'w-[120px] h-[200px]': size === 'medium',
            [`w-[${customWidth}] h-[${customHeight}] rounded-none`]:
              customHeight && customWidth
          }
        )}
      >
        <div className='w-full h-full relative'>
          {loading && !imageError && (
            <div className='w-full absolute flex justify-center items-center h-full bg-gradient-to-t from-black/50 to-transparent'>
              <SkeletonWrapper number={1} height='100%' width='100%' />
            </div>
          )}
          <img
            className={`w-full h-full object-cover hover:brightness-100 transition-all duration-150 ${!showImage && category === 'adult' && 'blur-2xl brightness-100'}`}
            src={imageSrc || Error}
            alt='img'
            onError={
              !imageError
                ? handleImageError
                : !intermediateFallbackImageError
                  ? handleIntermediateFallbackImageError
                  : handleFallbackImageError
            }
            onClick={() => {
              handleNavigation && handleNavigation(id)
            }}
            onLoad={handleImageLoad}
          />
          {!loading && (
            <>
              {category === 'adult' && (
                <div
                  className={`absolute top-1/2 right-1/2 transform translate-x-1/2 select-none -translate-y-1/2 w-full ${showImage && 'opacity-0'}`}
                >
                  <ImageContainer />
                </div>
              )}
              {size === 'medium' && (
                <>
                  <div className='top-2 left-3 text-white absolute'>
                    <TooltipWrapper
                      component={
                        <SocialIcon
                          className='w-5 h-5'
                          platform={category}
                          fill='white'
                        />
                      }
                      text={
                        category === 'adult'
                          ? 'Sensitive Content'
                          : category.charAt(0).toUpperCase() + category.slice(1)
                      }
                      delayDuration={20}
                    />
                  </div>
                  <div className='px-2 h-auto w-full absolute text-background bottom-2 flex items-end'>
                    <p className='text-xs line-clamp-2 text-wrap'>{title}</p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
