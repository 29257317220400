import { Badge } from '@/components/common/ui/badge'
import { getBadgeStyles, getBadgeText } from '../../../../../utils'
import _ from 'lodash'

export default function WatchtowerBadge({ text, watchtowerInfo }) {
  const badgeStyles = getBadgeStyles({ text })
  return (
    <Badge
      style={badgeStyles}
      className='text-sx font-medium flex items-center justify-center w-fit h-[20px] border-none'
      variant='outline'
    >
      <p className='flex justify-center items-center '>
        {watchtowerInfo ? getBadgeText({ text }) : _.upperFirst(text)}
      </p>
    </Badge>
  )
}
