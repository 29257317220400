export const FETCH_TAKEDOWNS = 'FETCH_TAKEDOWNS'
export const FETCH_TAKEDOWNS_SUCCESS = 'FETCH_TAKEDOWNS_SUCCESS'
export const FETCH_TAKEDOWNS_FAILURE = 'FETCH_TAKEDOWNS_FAILURE'

export const FETCH_IMPERSONATIONS = 'FETCH_IMPERSONATIONS'
export const FETCH_IMPERSONATIONS_SUCCESS = 'FETCH_IMPERSONATIONS_SUCCESS'
export const FETCH_IMPERSONATIONS_FAILURE = 'FETCH_IMPERSONATIONS_FAILURE'

export const FETCH_SINGLE_TAKEDOWN = 'FETCH_SINGLE_TAKEDOWN'
export const FETCH_SINGLE_TAKEDOWN_SUCCESS = 'FETCH_SINGLE_TAKEDOWN_SUCCESS'
export const FETCH_SINGLE_TAKEDOWN_FAILURE = 'FETCH_SINGLE_TAKEDOWN_FAILURE'

export const FETCH_MAILS = 'FETCH_MAILS'
export const FETCH_MAILS_SUCCESS = 'FETCH_MAILS_SUCCESS'
export const FETCH_MAILS_FAILURE = 'FETCH_MAILS_FAILURE'

export const CREATE_TAKEDOWN = 'CREATE_TAKEDOWN'
export const CREATE_TAKEDOWN_SUCCESS = 'CREATE_TAKEDOWN_SUCCESS'
export const CREATE_TAKEDOWN_FAILURE = 'CREATE_TAKEDOWN_FAILURE'

export const GET_TAKEDOWN_SUMMARY = 'GET_TAKEDOWN_SUMMARY'
export const GET_TAKEDOWN_SUMMARY_SUCCESS = 'GET_TAKEDOWN_SUMMARY_SUCCESS'
export const GET_TAKEDOWN_SUMMARY_FAILURE = 'GET_TAKEDOWN_SUMMARY_FAILURE'
