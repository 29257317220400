import Logo from '@/assets/Logo.svg'
import { CardWrapper } from '@/components/common/Wrappers/Card/CardWrapper'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction } from '@/redux/Auth/actions'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import { validateForm } from './utils'
import Fields from './components/Fields'
import Footer from './components/Footer'
import { useNavigationFromToken } from '@/pages/Auth/hooks/useNavigationFromToken.js'

const initialState = {
  email_address: '',
  password: ''
}

export default function Auth() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errorMessage } = useSelector((state) => state.authReducer)

  const [formData, setFormData] = useState(initialState)
  const [error, setError] = useState(initialState)
  const { auth, loading, cross_token } = useStore(globalStore)

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }))
  }

  const handleSubmit = () => {
    if (validateForm({ formData, setError })) {
      dispatch(
        loginAction({
          payload: formData
        })
      )
    }
  }

  useNavigationFromToken({ cross_token, auth, navigate })

  return (
    <div className='w-screen h-screen card-bg-img'>
      <div className='flex w-screen lg:w-[50%] bg-var-background justify-center h-full items-center'>
        <div className='flex flex-col items-center justify-center w-[80%] md:w-[45%] lg:w-[56%] '>
          <div className='w-full'>
            <div className='pb-5'>
              <img src={Logo} alt='Loti logo' />
            </div>
            <div className='pt-4 pb-3'>
              <h3 className=' text-2xl font-semibold'>Log in</h3>
              <p className=' text-sm font-normal pt-2 text-text-muted'>
                Welcome back! Please sign in to continue.
              </p>
            </div>
          </div>
          <div className='w-full '>
            <CardWrapper
              fields={<Fields error={error} handleChange={handleChange} />}
              cardFooter={
                <Footer
                  handleSubmit={handleSubmit}
                  loading={loading}
                  errorMessage={errorMessage}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
