import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'

export default function Footer({ handleSubmit, loading, errorMessage = null }) {
  return (
    <div className='flex flex-col justify-center w-full'>
      {errorMessage ? (
        <div className='text-red-600 px-1 text-sm text-center mb-4'>
          {errorMessage}
        </div>
      ) : null}
      <ButtonWrapper
        onClick={handleSubmit}
        className='w-full'
        text='Log in'
        loading={loading}
        disabled={loading}
      />
    </div>
  )
}
