import { Separator } from '@/components/common/ui/separator'
import ActivityTimeline from './ActivityTimeline'
import { useEffect, useState } from 'react'
import Confirmation from './ActivityTabContent/Confirmation'
import NoticeIssued from './ActivityTabContent/NoticeIssued'
import ContentMatch from './ActivityTabContent/ContentMatch'
import takedownStore from '@/pages/Takedowns/takedownStore'

const mailTitles = {
  domain: 'Takedown Issued',
  hosting: 'Hosting Provider Notification',
  registrar: 'Registrar Notification',
  legal: 'Legal Team Notification'
}

const mailOrder = ['domain', 'hosting', 'registrar', 'legal']

export default function AllActivity({ status, takedown, mails, date }) {
  const [selectedTab, setSelectedTab] = useState('')
  const tableTab = takedownStore((store) => store.selectedTab)
  useEffect(() => {
    const getInitialTab = () => {
      switch (status) {
        case 'created':
          return 'content_match'
        case 'processed':
          return 'domain'
        case 'escalated':
          return 'notice_issue'
        case 'completed':
          return 'confirmation'
        default:
          return 'content_match'
      }
    }
    setSelectedTab(getInitialTab())
  }, [status])
  const renderTimelineItems = () => {
    const timelineItems = [
      <ActivityTimeline
        key='content_match'
        title='Infringing content match'
        content='A scraper picked up a match for your likeness.'
        last={true}
        status={status}
        onClick={() => setSelectedTab('content_match')}
        id='content_match'
        selectedTab={selectedTab}
        date={date?.created}
      />
    ]
    if (status !== 'created' && !mails.domain) {
      timelineItems.push(
        <ActivityTimeline
          key={'domain'}
          title={mailTitles?.domain}
          onClick={() => setSelectedTab('domain')}
          id={'domain'}
          selectedTab={selectedTab}
          status={status}
          date={date?.takedown}
        />
      )
    }

    mailOrder.forEach((info_type) => {
      if (mails[info_type]) {
        timelineItems.push(
          <ActivityTimeline
            key={info_type}
            title={mailTitles[info_type]}
            onClick={() => setSelectedTab(info_type)}
            id={info_type}
            selectedTab={selectedTab}
            date={date?.takedown}
            status={status}
          />
        )
      }
    })

    if (status === 'completed') {
      timelineItems.push(
        <ActivityTimeline
          key='confirmation'
          title='Content removal confirmation'
          content='Content no longer exists on domain'
          onClick={() => setSelectedTab('confirmation')}
          id='confirmation'
          selectedTab={selectedTab}
          date={date?.success}
          status={status}
        />
      )
    }
    return timelineItems.reverse()
  }

  const renderTimelineContent = () => {
    if (selectedTab === 'content_match') {
      return (
        <ContentMatch
          face_url={takedown?.face_url}
          image_url={
            tableTab === 'media'
              ? takedown?.face_url
              : takedown.profile_image_url
          }
          asset_url={takedown?.asset_url}
          frame_url={takedown?.frame_url}
          url={takedown?.page_url}
          category={takedown?.category}
          username={takedown.username}
        />
      )
    } else if (selectedTab === 'confirmation') {
      return (
        <Confirmation url={takedown?.page_url} date={takedown?.updatedAt} />
      )
    } else if (mailOrder?.includes(selectedTab)) {
      return (
        <NoticeIssued
          mail={mails[selectedTab] ? mails[selectedTab][0]?.emails : ''}
          type={selectedTab}
          email_address={
            mails[selectedTab] ? mails[selectedTab][0]?.email_address : ''
          }
        />
      )
    }
    return null
  }

  return (
    <div className='pb-8'>
      <div className='font-medium text-[16px] pb-2'>
        <p>All activity</p>
      </div>
      <Separator />
      <div>
        <div className='relative overflow-hidden'>
          <div className='grid-cols-10 grid gap-8 w-full'>
            <div className='sm:col-span-4 col-span-full pt-5'>
              {renderTimelineItems()?.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
            <div className='sm:col-span-6 col-span-full sm:border-l pt-5 h-full'>
              <div className='h-full'>{renderTimelineContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
