import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper'
import { useEffect } from 'react'
import { useStore } from 'zustand'
import profileStore from '@/pages/Profile/profileStore.js'

export default function TakedownInfoSection({ userData }) {
  const { handleChange, userFormData, setUserFormData } = useStore(profileStore)

  useEffect(() => {
    if (userData) {
      const { full_name, street, suite, city, state, zip_code, country } =
        userData
      setUserFormData({
        full_name,
        street,
        suite,
        city,
        state,
        zip_code,
        country
      })
    }
  }, [userData])

  return (
    <div className='grid grid-cols-10'>
      <div className='sm:col-span-5 col-span-full text-base font-medium'>
        <div>
          <p>Takedown information</p>
        </div>
        <div className='text-sm font-normal'>
          <p>
            This information is appended to all takedowns. This is required by
            law for them to be effective.
          </p>
        </div>
      </div>
      <div className='sm:col-span-5 col-span-full py-2'>
        <div className='pt-1'>
          <div className='text-sm font-normal'>
            <p>Full name</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              placeholder='Dave Hawkins'
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.full_name}
              value={userFormData.full_name || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'full_name' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>Street Address</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.street}
              value={userFormData.street || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'street' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>Suite / Unit (optional)</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.suite}
              value={userFormData.suite || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'suite' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>City</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.city}
              value={userFormData.city || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'city' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>State</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.state}
              value={userFormData.state || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'state' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>ZIP code</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.zip_code}
              value={userFormData.zip_code || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'zip_code' })
              }
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='text-sm font-normal'>
            <p>Country</p>
          </div>
          <div className='flex items-center py-1'>
            <InputWrapper
              className='text-sm font-normal text-foreground w-96'
              defaultValue={userData?.country}
              value={userFormData.country || ''}
              onChange={(e) =>
                handleChange({ value: e.target.value, key: 'country' })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
