import FaceScansContainer from './FaceScansContainer'

export default function FaceScans() {
  return (
    <div className='grid grid-cols-3 gap-16'>
      <div className='col-span-1'>
        <div className=' font-semibold'>
          <p>3D Scans</p>
        </div>
        <div className=' text-sm text-muted-foreground'>
          <p>
            Highly detailed scans of your face and body, provided by Loti to
            brands that are given access.
          </p>
        </div>
      </div>
      <div className='col-span-2 flex gap-4 border rounded-md py-1'>
        <FaceScansContainer />
      </div>
    </div>
  )
}
