import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'
import API from '@/axios/Axios'
import { toast } from 'sonner'

function* loginSaga({ payload }) {
  try {
    yield put({ type: actionTypes.LOGIN_REQUESTED })
    const { data } = yield API.post('/user/authenticate', payload)
    if (data.success) {
      const loginTime = new Date().toISOString()
      yield put({
        type: actionTypes.LOGIN_SUCCESS,
        payload: { ...data.data, loginTime }
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOGIN_FAILURE,
      payload: error?.response?.data?.message
    })
  }
}

function* crossLogin({ payload }) {
  try {
    const headers = {
      'agent-token': payload
    }

    const { data } = yield API.post('/user/agent-auth', {}, { headers })
    if (data.success) {
      yield put({
        type: actionTypes.CROSS_LOGIN_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    toast.error('Something went wrong.', {
      description:
        error?.response?.data?.message + '.' ||
        'An error occured while logging you in.'
    })
    yield put({
      type: actionTypes.CROSS_LOGIN_FAILURE,
      payload: error?.response?.data
    })
  }
}
function* authSaga() {
  yield all([
    takeLatest(actionTypes.LOGIN, loginSaga),
    takeLatest(actionTypes.CROSS_LOGIN, crossLogin)
  ])
}

export default authSaga
