import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore'
import { getTakedownSummary } from '@/redux/Takedowns/actions'
import { calculateTakedownData } from '@/pages/Takedowns/utils.js'

export const useFetchTakedownSummary = () => {
  const dispatch = useDispatch()
  const { takedownSummary, setTakedownSummaryCount } = useStore(takedownStore)

  useEffect(() => {
    if (!takedownSummary) {
      dispatch(getTakedownSummary({ payload: { type: 'recent' } }))
    }
  }, [takedownSummary, dispatch])

  useEffect(() => {
    if (takedownSummary) {
      const progressData = calculateTakedownData(takedownSummary)

      if (progressData) {
        setTakedownSummaryCount(progressData)
      }
    }
  }, [takedownSummary, setTakedownSummaryCount])
}
