import globalStore from '@/zustand/globalStore'
import * as actionTypes from './actionTypes'

const initialState = {
  error: false,
  loading: false,
  userData: null,
  errorMessage: ''
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DATA_REQUESTED: {
      globalStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.GET_USER_DATA_SUCCESS: {
      globalStore.getState().setLoading(false)
      globalStore.getState().setUserData(action.payload)
      globalStore.getState().setUserMetaData({
        full_name: action?.payload?.full_name,
        email_address: action?.payload?.email_address,
        user_id: action?.payload?.id
      })
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        userData: action.payload
      }
    }
    case actionTypes.GET_USER_DATA_FAILURE: {
      globalStore.getState().setLoading(false)
      globalStore.getState().setUserData(null)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
        userData: null
      }
    }
    case actionTypes.FETCH_DELEGATES: {
      globalStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.FETCH_DELEGATES_SUCCESS: {
      globalStore.getState().setLoading(false)
      globalStore.getState().setDelegates(action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.FETCH_DELEGATES_FAILURE: {
      globalStore.getState().setLoading(false)
      globalStore.getState().setDelegates(null)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message
      }
    }
    case actionTypes.UPDATE_USER_DATA: {
      globalStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.UPDATE_USER_DATA_SUCCESS: {
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.UPDATE_USER_DATA_FAILURE: {
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message
      }
    }
    case actionTypes.CLEAR_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      }
    }

    default:
      return state
  }
}

export default userReducer
