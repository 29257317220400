import { Loader2 } from 'lucide-react'

export default function ToastContainer({
  title,
  description,
  onView,
  loading
}) {
  return (
    <div>
      <div className='font-medium text-sm'>{title}</div>
      <div className='font-normal text-xs text-muted-foreground pt-1'>
        {description}
      </div>
      {onView && (
        <div
          className='underline cursor-pointer items-center flex gap-2'
          onClick={(e) => {
            loading ? e.stopPropagation() : onView()
          }}
        >
          {loading && <Loader2 className='animate-spin w-4' />} <p>View</p>
        </div>
      )}
    </div>
  )
}
