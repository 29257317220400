import { create } from 'zustand'
const profileStore = create((set) => ({
  loading: false,
  setLoading: (value) => set({ loading: value }),

  faces: null,
  setfaces: (value) => set({ faces: value }),

  searches: null,
  setSearches: (value) => set({ searches: value }),

  success: false,
  setSuccess: (value) => set({ success: value }),

  error: false,
  setError: (value) => set({ error: value }),

  userFormData: {
    full_name: '',
    street: '',
    suite: '',
    city: '',
    state: '',
    zip_code: '',
    country: ''
  },

  setUserFormData: (value) => set({ userFormData: value }),

  handleChange: ({ value, key }) => {
    set((state) => ({
      userFormData: {
        ...state.userFormData,
        [key]: value
      }
    }))
  },

  clear: () =>
    set({
      loading: false,
      faces: null,
      searches: null,
      success: false,
      error: false
    })
}))
export default profileStore
