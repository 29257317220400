import * as actionTypes from './actionTypes'

export const getSearches = ({ payload }) => ({
  type: actionTypes.GET_SEARCHES,
  payload
})

export const getSingleSearch = ({ payload }) => ({
  type: actionTypes.GET_SINGLE_SEARCH,
  payload
})

export const getSearchSummary = ({ payload }) => ({
  type: actionTypes.GET_SEARCH_SUMMARY,
  payload
})

export const getSearchSummaryChart = ({ payload }) => ({
  type: actionTypes.GET_SEARCH_SUMMARY_CHART_DATA,
  payload
})
export const getDashboardDeepfakes = ({ payload }) => ({
  type: actionTypes.GET_DASHBOARD_DEEPFAKES,
  payload
})
export const getDashboardVideos = ({ payload }) => ({
  type: actionTypes.GET_DASHBOARD_VIDEOS,
  payload
})
export const getDashboardImpersonations = ({ payload }) => ({
  type: actionTypes.GET_DASHBOARD_IMPERSONATIONS,
  payload
})
export const getDashboardSocial = ({ payload }) => ({
  type: actionTypes.GET_DASHBOARD_SOCIAL,
  payload
})
export const getSingleImpersonatorData = ({ payload }) => ({
  type: actionTypes.GET_SINGLE_IMPERSONATOR_DATA,
  payload
})
export const setIsMe = ({ payload }) => ({
  type: actionTypes.SET_IS_ME,
  payload
})
export const setIsNotDeepfake = ({ payload }) => ({
  type: actionTypes.SET_IS_NOT_DEEPFAKE,
  payload
})
