const TikTokIcon = ({ fill = 'currentColor',...rest }) => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.913 6.55938C12.5453 6.56232 11.2113 6.13271 10.0994 5.33125V10.9188C10.0991 11.9536 9.78475 12.9636 9.19849 13.8139C8.61222 14.6641 7.78195 15.3139 6.81872 15.6764C5.85549 16.0389 4.80521 16.0969 3.80837 15.8425C2.81152 15.5881 1.91562 15.0335 1.24049 14.2529C0.565367 13.4723 0.143197 12.5029 0.0304455 11.4743C-0.0823058 10.4457 0.119737 9.40696 0.609553 8.49705C1.09937 7.58713 1.8536 6.84939 2.77139 6.3825C3.68917 5.9156 4.72675 5.74182 5.74535 5.88438V8.69375C5.27959 8.54622 4.77942 8.55053 4.31624 8.70608C3.85307 8.86163 3.45058 9.16047 3.16624 9.55991C2.88191 9.95935 2.73026 10.439 2.73296 10.9303C2.73566 11.4216 2.89256 11.8996 3.18127 12.2958C3.46998 12.6921 3.87573 12.9864 4.34058 13.1368C4.80544 13.2872 5.30563 13.2859 5.76973 13.1332C6.23384 12.9805 6.63813 12.6842 6.92489 12.2865C7.21164 11.8888 7.36619 11.4101 7.36646 10.9188V0H10.0994C10.0978 0.232583 10.1176 0.464814 10.1584 0.69375C10.2534 1.20406 10.4509 1.68951 10.7387 2.12044C11.0265 2.55136 11.3986 2.91869 11.8323 3.2C12.4496 3.61026 13.1732 3.82871 13.913 3.82813V6.55938Z'
        fill={fill}
      />
    </svg>
  )
}
export default TikTokIcon
