import { Label } from '@/components/common/ui/label'
import PropTypes from 'prop-types'
export default function LabelWrapper({ htmlFor, className, text }) {
  return (
    <Label htmlFor={htmlFor} className={className}>
      {text}
    </Label>
  )
}
LabelWrapper.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired
}
