import { Badge } from '@/components/common/ui/badge.jsx'
import { convertTextToLabel, getBadgeStyles } from '../../../utils.js'

export default function TakedownCustomBadge({ text }) {
  const badgeStyles = getBadgeStyles({ text })
  const label = convertTextToLabel({ text })

  return (
    <Badge
      style={badgeStyles}
      className='text-sx font-medium flex items-center justify-center w-fit h-[20px] border-none'
      variant='outline'
    >
      <p className='flex justify-center items-center py-[1px]'>{label}</p>
    </Badge>
  )
}
