import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper'
import LabelWrapper from '@/components/common/Wrappers/Label/LabelWrapper'
import { Eye, EyeOff } from 'lucide-react'
import { useState } from 'react'

export default function Fields({ handleChange, error }) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className='flex flex-col'>
        <LabelWrapper
          text='Email address'
          htmlFor='email'
          className={`font-medium pb-3 ${error.email ? 'text-red-600' : ''}`}
        />
        <InputWrapper
          type='email'
          placeholder='email@example.com'
          className='font-normal'
          onChange={(e) => {
            handleChange('email_address', e.target.value)
          }}
        />
      </div>
      <div className='text-red-600 px-1 text-sm'>{error.email}</div>
      <div className='flex flex-col space-y-1.5'>
        <LabelWrapper
          text='Password'
          htmlFor='Password'
          className={`font-medium ${error.password ? 'text-red-600' : ''}`}
        />
        <div className='relative'>
          <InputWrapper
            type={showPassword ? 'text' : 'password'}
            className='font-normal'
            onChange={(e) => {
              handleChange('password', e.target.value)
            }}
          />
          <button
            type='button'
            onClick={togglePasswordVisibility}
            className='absolute right-2 top-1/2 transform -translate-y-1/2'
          >
            {showPassword ? (
              <EyeOff className='w-4 h-4' />
            ) : (
              <Eye className='w-4 h-4' />
            )}
          </button>
        </div>
        <div className='text-red-600 px-1 text-sm'>{error.password}</div>
      </div>
    </>
  )
}
