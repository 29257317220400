import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useNavigationFromToken = ({ cross_token, auth, navigate }) => {
  const location = useLocation()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tokenFromParams = queryParams.get('agent-token')
    // Only navigate to the home page if the token is present in the URL and cross_token is set.
    if (tokenFromParams && !cross_token) {
      return
    }
    if (auth && auth?.session_token) {
      navigate('/')
    }
  }, [auth, navigate, cross_token])
}
