import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import { useStore } from 'zustand'
import MessageSection from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewRequiredTabs/components/MessageSection.jsx'
import Card from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewRequiredTabs/components/Card.jsx'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import ActionDock from '@/components/ActionDock/ActionDock.jsx'
import { CheckCheck, CircleX } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { setIsMe } from '@/redux/Watchtower/actions.js'
import { Tabs, TabsList, TabsTrigger } from '@/components/common/ui/tabs.jsx'
import TabCard from '@/components/TabCard/TabCard.jsx'

const tabs = [
  {
    label: 'All',
    value: 'all'
    // contentValue: counts.all.toLocaleString('en-us')
  },
  {
    label: 'Impersonations',
    value: 'impersonations'
    // contentValue: counts.impersonations.toLocaleString('en-us')
  },
  {
    label: 'Deepfakes',
    value: 'deepfake'
    // contentValue: counts.deepfake.toLocaleString('en-us')
  },
  {
    label: 'Adult',
    value: 'adult'
    // contentValue: counts.adult.toLocaleString('en-us')
  },
  {
    label: 'Social',
    value: 'social'
    // contentValue: counts?.social?.toLocaleString('en-us')
  }
]

const ReviewRequiredTabs = () => {
  const {
    loading,
    markedForDelete,
    setMarkedForDelete,
    reviewRequiredData,
    selectedReviewRequiredTab,
    setSelectedReviewRequiredTab
  } = useStore(watchtowerStore)
  const dispatch = useDispatch()
  const handleDelete = ({ key = true }) => {
    const ids = markedForDelete.join(',')
    dispatch(
      setIsMe({
        payload: {
          ids,
          isMe: key,
          type:
            selectedReviewRequiredTab === 'impersonations'
              ? 'impersonations'
              : null
        }
      })
    )
    setMarkedForDelete([])
  }
  const secondaryActions = [
    {
      text: 'This is me',
      variant: 'outline',
      icon: <CheckCheck className='w-4 text-green-600' />,
      alertTitle: 'This is me',
      alertDescription: 'Confirm that this content represents you.',
      alertOk: 'Confirm',
      alertCancel: 'Cancel',
      handlePrimary: () => {
        handleDelete({ key: true })
      }
    },
    {
      text: 'This is not me',
      variant: 'outline',
      icon: <CircleX className='w-4 text-red-600' />,
      alertTitle: 'This is not me',
      alertDescription: 'Confirm that this content does not represent you.',
      alertOk: 'Confirm',
      alertCancel: 'Cancel',
      handlePrimary: () => {
        handleDelete({ key: false })
      }
    }
  ]
  return (
    <div className='py-1 relative'>
      <MessageSection />
      <div className={'flex flex-wrap sm:gap-6 gap-0 justify-between py-4'}>
        <div className='w-full'>
          <Tabs
            defaultValue={selectedReviewRequiredTab}
            className='w-full h-full'
            value={selectedReviewRequiredTab}
            onValueChange={(value) => {
              setSelectedReviewRequiredTab(value)
            }}
          >
            <TabsList className='h-fit bg-transparent grid sm:grid-cols-5 grid-cols-4 gap-4 px-0'>
              {tabs.map((tab) => (
                <TabsTrigger
                  key={tab.value}
                  value={tab.value}
                  className='data-[state=active]:ring-2 data-[state=active]:border-transparent data-[state=active]:ring-green-600 data-[state=active]:text-green-600 p-0 rounded-lg text-foreground border sm:col-span-1 col-span-2'
                >
                  <TabCard
                    type={tab.label}
                    content={
                      <p className='text-lg font-semibold'>
                        {tab.contentValue}
                      </p>
                    }
                  />
                </TabsTrigger>
              ))}
            </TabsList>

            <div className={'flex flex-wrap justify-between w-full gap-4 pt-4'}>
              {(() => {
                if (loading) {
                  return Array.from({ length: 18 }).map((_, index) => (
                    <SkeletonWrapper
                      key={index}
                      width={'10rem'}
                      height={'15rem'}
                      number={1}
                    />
                  ))
                }
                return reviewRequiredData[selectedReviewRequiredTab]?.data?.map(
                  (item) => (
                    <div
                      key={item?.search_results_id ?? item?.username_platform}
                    >
                      <Card item={item} />
                    </div>
                  )
                )
              })()}
            </div>
          </Tabs>
        </div>
      </div>
      <div className='fixed bottom-16 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <div
          className={`flex justify-center transition-all duration-300 mb-4 ${
            markedForDelete.length > 0
              ? 'opacity-100 max-h-20'
              : 'opacity-0 max-h-0'
          }`}
        >
          <ActionDock
            secondaryActions={secondaryActions}
            dataLength={markedForDelete?.length}
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewRequiredTabs
