const TimelineStop = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-ban size-4 text-red-500'
    >
      <circle cx='12' cy='12' r='10'></circle>
      <path d='m4.9 4.9 14.2 14.2'></path>
    </svg>
  )
}
export default TimelineStop
