import { UserRoundCheck } from 'lucide-react'

const MessageSection = () => {
  return (
    <div className={'flex items-center gap-4 px-4 border py-2 rounded-md'}>
      <div>
        <UserRoundCheck className={'w-5 text-muted-foreground'} />
      </div>
      <div className={'flex flex-col'}>
        <div className={'font-medium text-sm'}>
          <p>Review Area</p>
        </div>
        <div className={'font-normal text-sm'}>
          <p>
            Results of your likeness we&apos;ve found across the web, we&apos;re
            not confident in whether these are definitely you or not. Please
            confirm.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MessageSection
