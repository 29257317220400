import FacialAssets from './components/FacialAssets'
import AudioAssets from './components/AudioAssets'
import FaceScans from './components/FaceScans'

export default function VaultActivity() {
  return (
    <div className='w-full max-w-[1440px]'>
      <div className='text-xl font-semibold text-text-foreground pb-12'>
        <p>Freja&apos;s Scan, 2024</p>
      </div>
      <div>
        <FacialAssets />
      </div>
      <div className='my-16'>
        <AudioAssets />
      </div>
      <div className=''>
        <FaceScans />
      </div>
    </div>
  )
}
