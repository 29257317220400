import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/common/ui/tabs'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import takedownStore from '@/pages/Takedowns/takedownStore'
import { useStore } from 'zustand'
import TabCard from '@/components/TabCard/TabCard'
import TakedownFilters from './TakedownFilters'
import TakedownTable from '@/pages/Takedowns/pages/Home/components/Tables/TakedownTable.jsx'
import { useFetchTakedownSummary } from '@/pages/Home/hooks/useFetchTakedownSummary'

const tabs = [
  {
    label: 'Media',
    value: 'media'
  },
  {
    label: 'Accounts',
    value: 'accounts'
  }
]

export default function TakedownTabs({ onTabChange }) {
  const {
    selectedTab,
    selectedColumns,
    takedownData,
    loading,
    takedownSummary
  } = useStore(takedownStore)
  useFetchTakedownSummary()

  const renderTabContent = (statsKey) => {
    return (
      <div className='border-t-2 text-left pt-3'>
        <div className='grid grid-cols-2 items-center'>
          <div>
            <p className='text-xs text-text-muted font-normal'>Requested</p>
            <p className='text-lg font-semibold'>
              {!takedownSummary ? (
                <SkeletonWrapper width={'100%'} height={'25px'} number={1} />
              ) : statsKey === 'media' ? (
                takedownSummary?.takedowns?.toLocaleString('en-us') || 0
              ) : (
                takedownSummary?.impersonator_takedowns?.toLocaleString(
                  'en-us'
                ) || 0
              )}
            </p>
          </div>

          <div className='pl-2'>
            <p className='text-xs text-text-muted font-normal'>Completed</p>
            <p className='text-lg font-semibold'>
              {!takedownSummary ? (
                <SkeletonWrapper width={'100%'} height={'25px'} number={1} />
              ) : statsKey === 'media' ? (
                takedownSummary?.takedowns_completed?.toLocaleString('en-us') ||
                0
              ) : (
                takedownSummary?.impersonator_takedowns_completed?.toLocaleString(
                  'en-us'
                ) || 0
              )}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='w-full'>
        <Tabs
          defaultValue={selectedTab}
          className='w-full h-[100%]'
          value={selectedTab}
          onValueChange={(value) => {
            onTabChange(value)
          }}
        >
          <TabsList className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full h-fit gap-4 bg-transparent'>
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.value}
                value={tab.value}
                className='data-[state=active]:ring-2 border data-[state=active]:border-transparent data-[state=active]:ring-green-600 data-[state=active]:text-green-600 p-0 rounded-lg text-foreground sm:col-span-1 col-span-2'
              >
                <TabCard
                  type={tab.label}
                  content={renderTabContent(tab.value)}
                />
              </TabsTrigger>
            ))}
          </TabsList>

          <div className='pt-2'>
            <TakedownFilters />
          </div>

          {tabs.map((tab) => (
            <TabsContent
              key={tab.value}
              value={tab.value}
              className='min-h-[calc(100vh-385px)]'
            >
              {loading ? (
                <SkeletonWrapper number={20} width={'100%'} height={'5rem'} />
              ) : (
                <TakedownTable
                  data={takedownData[selectedTab]?.data}
                  selectedColumns={selectedColumns}
                  selectedTab={selectedTab}
                />
              )}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </div>
  )
}
