import { Card, CardContent, CardHeader } from '@/components/common/ui/card.jsx'
import CustomBarChart from '../../../../components/BarChart/CustomBarChart.jsx'
import { cn, convertDateToMMMDD, toIntl } from '@/lib/utils.js'
import colors from 'tailwindcss/colors'
import { ResponsiveContainer } from 'recharts'

const StatsCard = ({
  label,
  value,
  icon,
  data,
  displayType,
  hover,
  handleBarHover,
  handleBarLeave,
  hoveredIndex,
  dateRange,
  name,
  loading,
  dataBreakdown = null
}) => {
  const color = colors.green[600]
  const processedData = data
    ?.map((item) => {
      const sum = Object.keys(item).reduce((total, key) => {
        if (!['createdAt', 'user_id', 'date']?.includes(key)) {
          total += item[key]
        }
        return total
      }, 0)
      return { date: item.date, totalResultsCount: sum }
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date))

  const adjustedData = processedData?.map((data, index, arr) => {
    const totalResultsCount =
      index === 0
        ? data.totalResultsCount
        : data.totalResultsCount - arr[index - 1].totalResultsCount

    return { date: data.date, totalResultsCount }
  })

  const count = adjustedData?.reduce(
    (sum, item) => sum + item.totalResultsCount,
    0
  )
  const avg = count / data?.length || 0
  return (
    <Card
      className={cn('shadow-sm flex flex-col w-full', {
        'px-4 py-4': displayType === 'numbers',
        'py-4 px-4': displayType === 'chart'
      })}
    >
      <CardHeader
        className={
          'p-0 flex w-full flex-row items-center space-y-0 gap-1 text-sm text-text-muted'
        }
      >
        {displayType === 'numbers' && icon}
        <p className='text-sm text-text-muted'>{label}</p>
      </CardHeader>
      <CardContent className={'px-0 pt-1 pb-0'}>
        {displayType === 'numbers' ? (
          <div className='w-full font-semibold text-xl flex flex-col'>
            <p className='text-md'>{value?.toLocaleString('en-us') || '0'}</p>

            {dataBreakdown && dataBreakdown?.length > 0 ? (
              <div className='flex flex-row items-center gap-6 mt-3'>
                {dataBreakdown.map((item, index) => (
                  <div
                    key={`${item.label}-${index}`}
                    onClick={item?.onClick}
                    className={`${item?.onClick ? `cursor-pointer group` : null}`}
                  >
                    <p className='text-xs text-text-muted font-normal mb-1'>
                      {item?.label}
                    </p>
                    <p className='text-base text-md group-hover:text-green-600 duration-300 ease-linear'>
                      {item?.value?.toLocaleString('en-us') || '0'}
                    </p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : (
          <div className=''>
            <h4 className='font-semibold text-xl'>
              {hover
                ? `${adjustedData[hoveredIndex]?.totalResultsCount.toLocaleString('en-us')} ${
                    name === 'takedownTime' ? 'Hours' : ''
                  }`
                : `${toIntl(
                    name === 'takedownTime'
                      ? avg.toFixed()
                      : loading
                        ? 0
                        : count || 0
                  )} ${name === 'takedownTime' ? 'Hours' : ''}`}
            </h4>
            <p className='text-xs h-[1rem] pt-1'>
              {hover
                ? convertDateToMMMDD(adjustedData[hoveredIndex]?.date)
                : ''}
            </p>
            {adjustedData && (
              <div className='pt-2'>
                <ResponsiveContainer width='100%' height={60}>
                  <CustomBarChart
                    dateRange={dateRange}
                    chartData={adjustedData ? adjustedData : []}
                    color={color}
                    handleBarHover={handleBarHover}
                    handleBarLeave={handleBarLeave}
                  />
                  <div className='flex justify-between -pt-1 text-muted-foreground'>
                    <p className='text-xs'>
                      {convertDateToMMMDD(adjustedData[0]?.date)}
                    </p>
                    <p className='text-xs'>
                      {convertDateToMMMDD(
                        adjustedData[adjustedData.length - 1]?.date
                      )}
                    </p>
                  </div>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default StatsCard
