import globalStore from '@/zustand/globalStore'
import * as actionTypes from './actionTypes'
import profileStore from '@/pages/Profile/profileStore'

const initialState = {
  error: false,
  loading: false,
  assets: false,
  errorMessage: '',
  faces: null
}

const assetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSETS: {
      profileStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        assets: null
      }
    }
    case actionTypes.GET_ASSETS_SUCCESS: {
      profileStore.getState().setLoading(false)
      globalStore.getState().setAssets(action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        assets: action.payload
      }
    }
    case actionTypes.GET_ASSETS_FAILURE: {
      profileStore.getState().setLoading(false)
      globalStore.getState().setAssets(null)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.UPLOAD_ASSETS: {
      profileStore.getState().setLoading(true)
      profileStore.getState().setError(false)
      profileStore.getState().setfaces(null)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        faces: null
      }
    }
    case actionTypes.UPLOAD_ASSETS_SUCCESS: {
      profileStore.getState().setLoading(false)
      profileStore.getState().setfaces(action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        assets: action.payload
      }
    }
    case actionTypes.UPLOAD_ASSETS_FAILURE: {
      profileStore.getState().setLoading(false)
      profileStore.getState().setError(true)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }
    case actionTypes.SAVE_ASSET: {
      profileStore.getState().setLoading(true)
      profileStore.getState().setError(false)
      profileStore.getState().setSuccess(false)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.SAVE_ASSETS_SUCCESS: {
      profileStore.getState().setLoading(false)
      profileStore.getState().setError(false)
      profileStore.getState().setfaces(action.payload)
      profileStore.getState().setSuccess(true)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.SAVE_ASSETS_FAILURE: {
      profileStore.getState().setLoading(false)
      profileStore.getState().setError(true)
      profileStore.getState().setSuccess(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    }

    default:
      return state
  }
}

export default assetsReducer
