import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../../ui/tooltip'

export default function TooltipWrapper({
  component,
  text,
  delayDuration,
  tooltipClassName = ''
}) {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger>{component}</TooltipTrigger>
        <TooltipContent className={tooltipClassName}>
          <p className='text-xs font-normal'>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
