export default function ImageContainer() {
  return (
    <div className=''>
      <div className='w-64 h-64 overflow-hidden relative rounded-xl border cursor-pointer'>
        <img
          className={`w-full h-full object-cover`}
          src={'https://www.goloti.dev/images/freja-2024.png'}
          alt='img'
        />
      </div>
      <div className='py-1 text-muted-foreground text-sm'>
        <p>img-1.jpeg</p>
      </div>
    </div>
  )
}
