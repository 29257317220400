import globalStore from '@/zustand/globalStore'
import * as actionTypes from './actionTypes'

const initialState = {
  error: false,
  loading: false,
  stats: null,
  errorMessage: ''
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATS:
      globalStore.getState().setLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    case actionTypes.GET_STATS_SUCCESS:
      globalStore.getState().setLoading(false)
      globalStore.getState().setDashboardStats(action.payload)
      return {
        ...state,
        loading: false,
        stats: action.payload
      }
    case actionTypes.GET_STATS_FAILURE:
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }

    default:
      return state
  }
}

export default dashboardReducer
