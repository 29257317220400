const YoutubeIcon = ({ fill = 'currentColor',...rest }) => {
  return (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M17.0912 1.87812C16.8943 1.1375 16.3162 0.55625 15.5818 0.359375C14.2505 0 8.91304 0 8.91304 0C8.91304 0 3.57554 0 2.24429 0.359375C1.50991 0.55625 0.931787 1.1375 0.734912 1.87812C0.378662 3.21875 0.378662 6.0125 0.378662 6.0125C0.378662 6.0125 0.378662 8.80625 0.734912 10.1469C0.931787 10.8875 1.50991 11.4438 2.24429 11.6406C3.57554 12 8.91304 12 8.91304 12C8.91304 12 14.2505 12 15.5818 11.6406C16.3162 11.4438 16.8943 10.8844 17.0912 10.1469C17.4474 8.80625 17.4474 6.0125 17.4474 6.0125C17.4474 6.0125 17.4474 3.21875 17.0912 1.87812ZM7.16929 8.55V3.475L11.6287 6.0125L7.16929 8.55Z'
        fill={fill}
      />
    </svg>
  )
}
export default YoutubeIcon
