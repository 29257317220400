export const validateForm = ({ formData, setError }) => {
  let errors = {}
  let formIsValid = true
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  if (formData.email_address === '') {
    formIsValid = false
    errors.email = 'Email cannot be empty'
  } else if (!emailRegex.test(formData.email_address)) {
    formIsValid = false
    errors.email = 'Invalid email address.'
  }
  if (formData.password === '') {
    formIsValid = false
    errors.password = 'Password cannot be empty'
  }
  setError(errors)
  return formIsValid
}
