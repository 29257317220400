import { ArrowRight, CircleX } from 'lucide-react'
import Image from '@/assets/icons/Image.png'
import { Link } from 'react-router-dom'

export default function CompleteProfileSection({
  setShowCompleteProfileSection,
  imgNumber
}) {
  return (
    <div className='p-6 bg-neutral-50'>
      <div className='flex justify-between items-center'>
        <div className=' text-xl pb-4 font-semibold'>
          <p>Complete your Loti profile</p>
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setShowCompleteProfileSection(false)
          }}
        >
          <CircleX size={'16px'} className='text-neutral-500' />
        </div>
      </div>
      <div className='grid grid-cols-10'>
        <div className='col-span-5 w-full '>
          <div className=''>
            <img src={Image} alt='img' />
          </div>
          <div className=' text-sm font-semibold py-2'>
            <p>Add photos</p>
          </div>
          <div className=' text-sm font-normal text-neutral-500 w-56'>
            <p>
              {imgNumber > 3
                ? 'Completed!'
                : ' Add at least three photos of your likeness.'}
            </p>
          </div>
          <div className='w-fit'>
            <Link to={'/profile'}>
              <div className='flex w-fit'>
                <div className=' py-2 text-sm font-medium text-text-muted'>
                  <p>{imgNumber > 3 ? '3 of 3' : `${imgNumber} of 3`}</p>
                </div>
                <div className='flex items-center px-2'>
                  <div className=' text-sm font-medium text-[#97C73D]'>
                    <p>{imgNumber > 3 ? 'View' : `Continue`}</p>
                  </div>
                  <div className='mt-[1px] px-1'>
                    <ArrowRight color={'#97C73D'} size={'14px'} />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/*<div className='col-span-5 w-full '>*/}
        {/*  <div className='col-span-5 w-full '>*/}
        {/*    <div className=''>*/}
        {/*      <img src={Audio} alt='img' />*/}
        {/*    </div>*/}
        {/*    <div className=' text-sm font-semibold py-2'>*/}
        {/*      <p>Add voice</p>*/}
        {/*    </div>*/}
        {/*    <div className=' text-sm font-normal text-neutral-500'>*/}
        {/*      <p>Completed!</p>*/}
        {/*    </div>*/}
        {/*    <div className='w-fit'>*/}
        {/*      <Link to={'/profile'}>*/}
        {/*        <div className='flex w-fit'>*/}
        {/*          <div className=' py-2 text-sm font-medium text-text-muted'>*/}
        {/*            <p>3 of 3</p>*/}
        {/*          </div>*/}
        {/*          <div className='flex items-center px-2'>*/}
        {/*            <div className=' text-sm font-medium text-[#97C73D]'>*/}
        {/*              <p>View</p>*/}
        {/*            </div>*/}
        {/*            <div className='mt-[1px] px-1'>*/}
        {/*              <ArrowRight color={'#97C73D'} size={'14px'} />*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
