import { crossLogin } from '@/redux/Auth/actions'
import globalStore from '@/zustand/globalStore'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useStore } from 'zustand'
import { clearAllZustandStores } from '@/lib/utils'
import routes from '@/routes/routes'

export const useCrossTokenFunctionality = () => {
  const { setCross_token } = useStore(globalStore)
  const queryParams = new URLSearchParams(location.search)
  const tokenFromParams = queryParams.get('agent-token')
  const dispatch = useDispatch()
  const prevLocation = useRef(location.pathname)
  const protectedRoutes = routes
    .filter((route) => route.path)
    .map((route) => route.path)
  useEffect(() => {
    //Only allowing cross login on the login route.
    if (
      tokenFromParams &&
      location.pathname === '/login' &&
      !protectedRoutes?.includes(prevLocation.current)
    ) {
      clearAllZustandStores()
      setCross_token(tokenFromParams)
      dispatch(crossLogin({ payload: tokenFromParams }))
    }
    prevLocation.current = location.pathname
  }, [tokenFromParams, location.pathname, setCross_token, dispatch])
}
