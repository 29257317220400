const WatchtowerIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="size-4"
    >
      <path
        d="M1.9083 8.01665C1.59588 9.2892 1.58795 10.6175 1.88516 11.8937C2.18236 13.1699 2.77633 14.358 3.61883 15.3616C4.46133 16.3652 5.52862 17.1559 6.73405 17.6697C7.93948 18.1834 9.24907 18.4057 10.5565 18.3184C11.8639 18.2311 13.1323 17.8367 14.2588 17.1672C15.3852 16.4978 16.3379 15.5721 17.0395 14.4655C17.7411 13.3588 18.1718 12.1022 18.2967 10.7978C18.4216 9.49347 18.2371 8.17803 17.7583 6.95831"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2671 2.33393C12.0617 1.82019 10.7521 1.59794 9.44464 1.68524C8.13721 1.77254 6.86879 2.16692 5.74237 2.83637C4.61594 3.50582 3.66326 4.43146 2.96167 5.53815C2.26007 6.64484 1.82934 7.90138 1.70444 9.20575C1.57955 10.5101 1.764 11.8256 2.24284 13.0453"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5336 6.46668C13.054 5.98432 12.481 5.60488 11.8498 5.35157C11.2185 5.09827 10.5422 4.97644 9.86221 4.99352C9.18223 5.01061 8.51288 5.16625 7.89513 5.45094C7.27737 5.73562 6.7242 6.14336 6.26946 6.6492C5.81471 7.15503 5.46796 7.74834 5.25041 8.39281C5.03287 9.03728 4.94911 9.71936 5.00425 10.3973C5.0594 11.0753 5.25229 11.7348 5.57113 12.3357C5.88997 12.9365 6.32805 13.466 6.85857 13.8917"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8449 8.8167C15.0043 9.47793 15.0272 10.1648 14.9121 10.8351C14.797 11.5055 14.5464 12.1454 14.1755 12.7156C13.8046 13.2857 13.3212 13.7742 12.755 14.1512C12.1888 14.5281 11.5517 14.7855 10.8825 14.9077C10.2134 15.0299 9.52637 15.0143 8.86348 14.8619C8.2006 14.7094 7.57579 14.4233 7.02729 14.021"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 11.6666C10.9206 11.6666 11.6668 10.9205 11.6668 9.99998C11.6668 9.07951 10.9206 8.33331 10.0002 8.33331C9.07969 8.33331 8.3335 9.07951 8.3335 9.99998C8.3335 10.9205 9.07969 11.6666 10.0002 11.6666Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1748 8.825L15.8915 4.10834"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="10" r="1.25" fill="black" />
    </svg>
  );
};

export default WatchtowerIcon;