import { Check, X } from 'lucide-react'

export default function ContractTerms({ badgeType }) {
  return (
    <div className='flex w-full'>
      <div className=' w-full flex justify-center items-center'>
        <ContractCard
          text={'Window'}
          type={badgeType}
          info={
            <div className='text-sm'>
              <p>6 months {badgeType === 'error' && '(In Breach)'}</p>
            </div>
          }
        />
      </div>
      <div className=' w-full flex justify-center items-center'>
        <ContractCard
          text={'Languages'}
          type={'success'}
          info={
            <div className='text-sm'>
              <p>English, French</p>
            </div>
          }
        />
      </div>
      <div className=' w-full flex justify-center items-center'>
        <ContractCard
          text='Geography'
          type={badgeType}
          info={
            <div className='flex text-sm items-center'>
              <p>US, UK, France,</p>{' '}
              {badgeType === 'error' && (
                <>
                  <X className='w-4 text-red-600' />
                  <p className='text-red-900'>Spain</p>
                </>
              )}
            </div>
          }
        />
      </div>
      <div className=' w-full flex justify-center items-center'>
        <ContractCard
          text={'Channels'}
          type={badgeType}
          info={
            <div className='flex text-sm items-center'>
              <p>Tik Tok, Instagram, Facebook, </p>{' '}
              {badgeType === 'error' && (
                <>
                  {' '}
                  <X className='w-4 text-red-600' />
                  <p className='text-red-900'>Youtube</p>
                </>
              )}
            </div>
          }
        />
      </div>
    </div>
  )
}

const ContractCard = ({ text, type, info }) => {
  const getColorByType = () => {
    let outerColor, innerColor
    switch (type) {
      case 'success':
        outerColor = '#BBF7D0'
        innerColor = '#15803D'
        break
      case 'error':
        outerColor = '#FECACA'
        innerColor = '#B91C1C'
        break
      default:
        break
    }
    return { outerColor, innerColor }
  }
  const colors = getColorByType()
  return (
    <div className='py-4'>
      <div className='w-full flex justify-center '>
        <div
          style={{ backgroundColor: colors.outerColor }}
          className=' p-2.5 rounded-full w-fit bg-red-300'
        >
          <div
            style={{ backgroundColor: colors.innerColor }}
            className='w-fit rounded-full p-[3px]'
          >
            {type === 'success' ? (
              <Check className='w-3 h-3 text-white' strokeWidth={3} />
            ) : (
              <X className='w-3 h-3 text-white' strokeWidth={3} />
            )}
          </div>
        </div>
      </div>
      <div className=' '>
        <div className='py-1 flex justify-center'>
          <p className='text-sm text-muted-foreground'>{text}</p>
        </div>
        <div className=' flex justify-center'>{info}</div>
      </div>
    </div>
  )
}
