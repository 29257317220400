import API from '@/axios/Axios'
import { all, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './actionTypes'
import { toast } from 'sonner'
import * as watchtowerActionTypes from '../Watchtower/actionTypes'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import { getSearches } from '@/redux/Watchtower/actions.js'
import { createPayload } from '@/pages/Watchtower/utils.js'
function* fetchTakedowns({ payload }) {
  try {
    const { lastKey, category, limit = 25 } = payload
    const config = {
      params: {
        lastKey,
        limit
      }
    }

    const endpoint =
      category === 'media' ? '/takedown/all' : '/takedown/impersonators/all'
    const { data } = yield API.get(endpoint, config)

    if (data.success) {
      yield put({
        type: actionTypes.FETCH_TAKEDOWNS_SUCCESS,
        payload: {
          results: data.data.results,
          lastKey: data.data.lastKey,
          category
        }
      })
    } else {
      yield put({
        type: actionTypes.FETCH_TAKEDOWNS_FAILURE,
        payload: data.message
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_TAKEDOWNS_FAILURE,
      payload: error?.message || 'An error occurred'
    })
  }
}

function* fetchSingleTakedown({ payload }) {
  try {
    const { id, category } = payload
    const endpoint =
      category === 'media'
        ? `/takedown?takedown_id=${id}`
        : `/takedown/impersonators?takedown_id=${id}`

    const { data } = yield API.get(endpoint)
    if (data.success) {
      yield put({
        type: actionTypes.FETCH_SINGLE_TAKEDOWN_SUCCESS,
        payload: {
          data: data.data
        }
      })
    } else {
      toast('An error ocurred', {
        description: 'Enable to fetch takedown details at the moment'
      })
      yield put({
        type: actionTypes.FETCH_SINGLE_TAKEDOWN_FAILURE,
        payload: data.message
      })
    }
  } catch (error) {
    toast('An error ocurred', {
      description: 'Enable to fetch takedown details at the moment'
    })
    yield put({
      type: actionTypes.FETCH_SINGLE_TAKEDOWN_FAILURE,
      payload: error?.message
    })
  }
}

function* fetchMails({ payload }) {
  try {
    const { data } = yield API.get(`/takedown/emails?takedown_id=${payload}`)
    if (data.success) {
      yield put({
        type: actionTypes.FETCH_MAILS_SUCCESS,
        payload: {
          data: data.data
        }
      })
    } else {
      toast('An error ocurred', {
        description: 'Enable to fetch takedown mails at the moment'
      })
      yield put({
        type: actionTypes.FETCH_MAILS_FAILURE,
        payload: data.message
      })
    }
  } catch (error) {
    toast('An error ocurred', {
      description: 'Enable to fetch takedown mails at the moment'
    })
    yield put({
      type: actionTypes.FETCH_MAILS_FAILURE,
      payload: error?.message
    })
  }
}

function* createTakedown({ payload }) {
  try {
    const { id, key } = payload
    const { data } = yield API.post(`/takedown/create?search_results_ids=${id}`)
    if (data.success) {
      toast('Takedown(s) initiated', {
        description: 'Your takedown request(s) have been initiated.'
      })
      yield put({
        type: actionTypes.CREATE_TAKEDOWN_SUCCESS,
        payload: {
          data: data.data
        }
      })
      if (key === 'array') {
        const watchtowerStoreState = watchtowerStore.getState()
        const watchtowerData = watchtowerStoreState.watchtowerData
        const selectedTab = watchtowerStoreState.selectedTab
        const page = watchtowerData[selectedTab].currentPage
        const reviewRequired = false
        const payload = createPayload({
          tab: selectedTab,
          watchtowerData,
          page,
          reviewRequired
        })
        yield put(getSearches({ payload }))
      } else {
        yield put({
          type: watchtowerActionTypes.GET_SINGLE_SEARCH,
          payload: `search_results_id=${id}`
        })
      }
    } else {
      yield put({
        type: actionTypes.CREATE_TAKEDOWN_FAILURE,
        payload: data.message
      })
    }
  } catch (error) {
    console.log(error)
    toast.error('An error occurred', {
      description: 'Enable to create takedown request at the moment'
    })
    yield put({
      type: actionTypes.CREATE_TAKEDOWN_FAILURE,
      payload: error?.message
    })
  }
}

function* getTakedownSummary({ payload }) {
  try {
    const { type } = payload
    const config = {
      params: {
        type
      }
    }
    const { data } = yield API.get(`/takedown/takedown-summary`, config)
    if (data.success) {
      yield put({
        type: actionTypes.GET_TAKEDOWN_SUMMARY_SUCCESS,
        payload: data.data
      })
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_TAKEDOWN_SUMMARY_FAILURE,
      payload: error?.message
    })
  }
}

function* takedownsSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_TAKEDOWNS, fetchTakedowns),
    takeLatest(actionTypes.FETCH_SINGLE_TAKEDOWN, fetchSingleTakedown),
    takeLatest(actionTypes.FETCH_MAILS, fetchMails),
    takeLatest(actionTypes.CREATE_TAKEDOWN, createTakedown),
    takeLatest(actionTypes.GET_TAKEDOWN_SUMMARY, getTakedownSummary)
  ])
}

export default takedownsSaga
