import { useEffect } from 'react'

export const useFetchData = ({ takedownData, selectedTab, fetchData }) => {
  useEffect(() => {
    const page = takedownData[selectedTab].currentPage
    if (!takedownData[selectedTab].data) {
      fetchData(page)
    }
  }, [selectedTab])
}
