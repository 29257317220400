import EnforceHomeCard from '../Home/components/EnforceHomeCard'
import { Separator } from '@/components/common/ui/separator'
import ActivityInfoSection from './components/ActivityInfoSection'
import ContractTerms from './components/ContractTerms'
import ZendayaImg from '@/assets/ZendayaImg.jpeg'
import { Loader2, ReceiptText } from 'lucide-react'
import { useParams } from 'react-router-dom'
export default function EnforceActivity() {
  const { id } = useParams()
  const enforceHomeCardsData = [
    {
      id: '1',
      img: 'https://www.goloti.dev/images/contracts/vitaminwater.jpg',
      title: 'Drink it in',
      text: 'Vitamin Water',
      badgeType: 'error',
      link: '/enforce/1'
    },
    {
      id: '2',
      img: 'https://www.goloti.dev/images/contracts/loreal.jpg',
      title: 'Live your way',
      text: `L'Oreal`,
      badgeType: 'error',
      link: '/enforce/2'
    },
    {
      id: '3',
      img: 'https://www.goloti.dev/images/contracts/swettybetty.jpg',
      title: '25 years strong',
      text: 'Sweaty Betty',
      badgeType: 'success',
      link: '/enforce/3'
    },
    {
      id: '4',
      img: 'https://www.goloti.dev/images/contracts/west-elm.jpg',
      title: 'In the studio with Freja',
      text: 'West Elm',
      badgeType: 'success',
      link: '/enforce/4'
    }
  ]
  const currentCard = enforceHomeCardsData?.find((item) => item.id === id)
  return (
    <div className='w-full max-w-[1440px]'>
      <div className='flex justify-between items-center text-muted-foreground'>
        <div className='flex text-sm items-center'>
          <div className=''>
            <ReceiptText className='w-4' />
          </div>
          <div className='px-2'>
            <p>Contract</p>
          </div>
        </div>
        <div>#{currentCard?.id}</div>
      </div>
      <div className='py-4'>
        <EnforceHomeCard
          img={currentCard?.img}
          title={currentCard?.title}
          text={currentCard?.text}
          badgeType={currentCard?.badgeType}
          activityPage={true}
        />
      </div>
      <Separator />
      {currentCard?.badgeType === 'error' && (
        <div className='py-6'>
          <div className=' text-[#992222] flex gap-2 items-center bg-[#FEF2F2] py-1 px-2 rounded-md'>
            <div className=''>
              <Loader2 className='animate-spin' />
            </div>
            <div className=''>
              <p className='text-sm font-medium '>
                We&apos;ve detected a breach of contract, your legal
                representative has been informed that your likeness has shown up
                on a disallowed channel.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className=''>
        <ActivityInfoSection currentCard={currentCard} />
      </div>
      <div className='pt-2 pb-4'>
        <div className='font-medium text-[16px] pb-4'>
          <p>Contract terms</p>
        </div>
        <Separator />
      </div>
      <div className=''>
        <ContractTerms badgeType={currentCard?.badgeType} />
      </div>
      <div className='pt-5 pb-4'>
        <div className='font-medium text-[16px]'>
          <p>Assets</p>
        </div>
        <div className='text-sm text-muted-foreground pb-4 pt-1'>
          <p>References to the campaign collected to enforce the contract.</p>
        </div>
        <Separator />
      </div>
      <div className=''>
        <div className='w-64 h-64 overflow-hidden relative rounded-xl border cursor-pointer'>
          <img
            className={`w-full h-full object-cover  `}
            src={ZendayaImg}
            alt='img'
          />
        </div>
        <div className='text-sm text-muted-foreground'>
          <p>loreal-1.jpeg</p>
        </div>
      </div>
    </div>
  )
}
