
export default function AllowedSubjects() {
  return (
    <div className='my-1 grid grid-cols-4 '>
      <div className=' col-span-1 pr-6'>
        <div className='text-muted-foreground text-sm'>
          <p>Allowed subjects</p>
        </div>
        <div className='text-sm py-1'>
          <p>
            Beauty, Sustainable Fashion, Lifestyle, Interior Design, Vegan Food
          </p>
        </div>
      </div>
      <div className=' col-span-1'>
        <div className='text-muted-foreground text-sm '>
          <p>Disallowed subjects</p>
        </div>
        <div className='text-sm py-1'>
          <p>Adult, Weapons, Gambling, Alcohol</p>
        </div>
      </div>
    </div>
  )
}
