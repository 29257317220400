import { Input } from '@/components/common/ui/input'
import PropTypes from 'prop-types'

export default function InputWrapper({
  type = 'text',
  placeholder,
  className,
  onChange,
  value,
  defaultValue,
  accept,
  ...rest
}) {
  return (
    <Input
      className={className}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      accept={accept}
      {...rest}
    />
  )
}
InputWrapper.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string
}
