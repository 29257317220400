import { Link } from 'react-router-dom'
import AllowedSubjects from './components/AllowedSubjects'
import Rules from './components/Rules'
import Scans from './components/Scans'

export default function Vault() {
  return (
    <div className='w-full'>
      <div className='h-20 bg-[#F9F9FA] px-16 relative'>
        <div className='border w-16 h-16 absolute -bottom-[40%] rounded-full overflow-hidden ring-4 ring-white shadow-2xl'>
          <img
            src='https://www.goloti.dev/images/freja-pic-large.png'
            alt=''
            className='object-cover'
          />
        </div>
      </div>
      <div className='py-10 px-16 '>
        <div className='pb-4'>
          <div>
            <p className='text-xl font-semibold text-text-foreground'>
              Freja Linberg
            </p>
          </div>
          <div className='text-muted-foreground text-sm '>
            <p>Actress, model, and content creator</p>
          </div>
        </div>
        <div className=''>
          <div>
            <Rules />
          </div>
          <div className='pb-4'>
            <AllowedSubjects />
          </div>
          <div className='pb-4'>
            <p className='text-xl font-semibold text-text-foreground'>Scans</p>
          </div>
          <div className='flex gap-10'>
            <Link to={'/vault/1'}>
              <div className=''>
                <Scans />
              </div>
            </Link>
            <Link to={'/vault/2'}>
              <div className=''>
                <Scans />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
