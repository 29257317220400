import { Link } from 'react-router-dom'
import EnforceHomeCard from './components/EnforceHomeCard'

export default function Enforce() {
  return (
    <div className='w-full max-w-[1440px]'>
      <div className='pb-8'>
        <p className='text-xl font-semibold text-text-foreground'>Enforce</p>
      </div>
      <div className=''>
        <div className='pb-4 border-b'>
          <Link to={'/enforce/1'}>
            <EnforceHomeCard
              img={'https://www.goloti.dev/images/contracts/vitaminwater.jpg'}
              title={'Drink it in'}
              text={'Vitamin Water'}
              badgeType={'error'}
            />
          </Link>
        </div>
        <div className='pt-6 pb-4 border-b'>
          <Link to={'/enforce/2'}>
            <EnforceHomeCard
              img={'https://www.goloti.dev/images/contracts/loreal.jpg'}
              title={'Live your way'}
              text={`L'Oreal`}
              badgeType={'error'}
            />
          </Link>
        </div>{' '}
        <div className='pt-6 pb-4 border-b'>
          <Link to={'/enforce/3'}>
            <EnforceHomeCard
              img={'https://www.goloti.dev/images/contracts/swettybetty.jpg'}
              title={'25 years strong'}
              text={'Sweaty Betty'}
              badgeType={'success'}
            />
          </Link>
        </div>{' '}
        <div className='pt-6 pb-4'>
          <Link to={'/enforce/4'}>
            <EnforceHomeCard
              img={'https://www.goloti.dev/images/contracts/west-elm.jpg'}
              title={'In the studio with Freja'}
              text={'West Elm'}
              badgeType={'success'}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
