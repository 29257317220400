import { useNavigate } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/common/ui/table.jsx'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore.js'
import RequiresReviewIcon from '@/assets/icons/RequiresReviewIcon.jsx'
import globalStore from '@/zustand/globalStore'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage'
import { Checkbox } from '@/components/common/ui/checkbox.jsx'
import {
  ChevronsUpDown,
  ArrowUp,
  ArrowDown,
  SquareArrowOutUpRight,
  ArrowUpFromDot,
  ArrowDownToDot
} from 'lucide-react'
import { cn } from '@/lib/utils.js'
import { useState } from 'react'
import { DropdownMenuFilter } from '@/components/common/DropdownMenuFilter/DropdownMenuFilter.jsx'
import BadgeWrapper from '@/components/common/Wrappers/Badge/BadgeWrapper.jsx'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import SocialIcon from '@/components/common/ui/social-icon'
import { fetchSearches } from '@/pages/Watchtower/utils.js'
import { useDispatch } from 'react-redux'
import { useCheckResultType } from '@/pages/Watchtower/hooks/useCheckResultType.js'

const getThreatLevel = (threatLevel) => {
  if (threatLevel >= 90 || threatLevel === 'critical') {
    return <BadgeWrapper text={'Critical'} variant={'destructive'} />
  } else if (
    (threatLevel >= 80 && threatLevel < 90) ||
    threatLevel === 'high'
  ) {
    return <BadgeWrapper text={'High'} variant={'underreview'} />
  } else if (
    (threatLevel >= 70 && threatLevel < 80) ||
    threatLevel === 'moderate'
  ) {
    return <BadgeWrapper text={'Moderate'} variant={'warning'} />
  } else {
    return null
  }
}

const getIcon = (threatLevelSort) => {
  if (threatLevelSort === 'acs') {
    return <ArrowUp className={'w-4'} strokeWidth={1.5} />
  } else if (threatLevelSort === 'dcs') {
    return <ArrowDown className={'w-4'} strokeWidth={1.5} />
  } else {
    return <ChevronsUpDown className={'w-4'} />
  }
}
const initSortConfig = { key: null, direction: 'asc' }
export default function WatchtowerTable({ data, selectedTab }) {
  const navigate = useNavigate()
  const {
    selectedColumns,
    selectedResults,
    setSelectedResults,
    watchtowerData,
    threatLevel,
    setWatchtowerData,
    sortConfig,
    setSortConfig,
    disableTakedownButton,
    setDisableTakedownButton
  } = useStore(watchtowerStore)
  const { hideSensitiveContent } = useStore(globalStore)
  const [threatLevelSort, setThreatLevelSort] = useState(null)
  const handleNavigation = (id) => {
    navigate(`/watchtower/${id}`)
  }
  const dispatch = useDispatch()

  const handleSelect = (e) => {
    const { checked, id } = e
    if (id === 'all') {
      if (checked) {
        setSelectedResults(
          data.map((item) =>
            selectedTab === 'impersonations'
              ? item.username_platform
              : item.search_results_id
          )
        )
      } else {
        setSelectedResults([])
      }
    } else if (checked) {
      setSelectedResults([...selectedResults, id])
    } else {
      setSelectedResults(selectedResults.filter((item) => item !== id))
    }
  }

  useCheckResultType({
    setDisableTakedownButton,
    selectedResults,
    watchtowerData
  })

  const sortedData = () => {
    if (!data) return []
    if (!threatLevelSort) return data

    let sorted = [...data]

    if (threatLevelSort) {
      sorted = sorted.sort((a, b) => {
        const scoreA =
          selectedTab === 'impersonations' ? a.overall_score : a.threat_score
        const scoreB =
          selectedTab === 'impersonations' ? b.overall_score : b.threat_score

        if (threatLevelSort === 'acs') {
          return scoreB - scoreA
        } else if (threatLevelSort === 'dcs') {
          return scoreA - scoreB
        }
        return 0
      })
    }
    return sorted
  }

  const handleSort = (key, direction) => {
    if (sortConfig.key === key && sortConfig.direction === direction) {
      // Reset sortConfig and appliedSort
      setSortConfig(initSortConfig)
      setWatchtowerData(selectedTab, {
        appliedSort: {
          ...watchtowerData[selectedTab].appliedSort,
          [key]: null
        },
        //* Reset currentPage and pageKeys
        currentPage: 1,
        pageKeys: {}
      })
      fetchSearches({
        dispatch,
        tab: selectedTab,
        data: watchtowerData,
        page: 1,
        filters: { threat_level: threatLevel[0] }
      })
    } else {
      // Update sortConfig and appliedSort
      setSortConfig({ key, direction })
      setWatchtowerData(selectedTab, {
        appliedSort: {
          ...watchtowerData[selectedTab].appliedSort,
          [key]: direction
        },
        //* Reset currentPage and pageKeys
        currentPage: 1,
        pageKeys: {}
      })
      fetchSearches({
        dispatch,
        tab: selectedTab,
        data: watchtowerData,
        page: 1,
        filters: { threat_level: threatLevel[0] },
        sort: {
          [key]: direction
        }
      })
    }
  }
  const renderSortIcons = (key) => {
    return (
      <span className='flex items-center ml-1'>
        <ArrowUpFromDot
          onClick={() => handleSort(key, 'asc')}
          className={`w-3 h-3 cursor-pointer ${sortConfig?.key === key ? (sortConfig.direction === 'asc' ? 'text-green-500' : 'text-text-muted') : null}`}
        />
        <ArrowDownToDot
          onClick={() => handleSort(key, 'desc')}
          className={`w-3 h-3 cursor-pointer ${sortConfig?.key === key ? (sortConfig.direction === 'desc' ? 'text-green-500' : 'text-text-muted') : null}`}
        />
      </span>
    )
  }

  return (
    <Table className='h-full w-full border-b'>
      <TableHeader>
        <TableRow className='h-full bg-gray-100 hover:bg-muted/50'>
          <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
            <Checkbox
              id='all'
              onCheckedChange={(e) => handleSelect({ checked: e, id: 'all' })}
              className={cn(
                selectedResults?.length === data?.length
                  ? 'data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                  : 'data-[state=checked]:bg-background data-[state=checked]:text-foreground'
              )}
              disabled={!(selectedTab === 'adult')}
              checked={selectedResults?.length > 0}
            />
          </TableHead>
          {selectedColumns?.includes('result') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Result
            </TableHead>
          )}
          {selectedColumns?.includes('followers') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              <span className='flex items-center'>
                Followers {renderSortIcons('followers')}
              </span>
            </TableHead>
          )}
          {selectedColumns?.includes('following') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              <span className='flex items-center'>Following</span>
            </TableHead>
          )}
          {selectedColumns?.includes('source') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Source
            </TableHead>
          )}
          {selectedColumns?.includes('threat_level') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              <DropdownMenuFilter
                text={'Threat Level'}
                icon={getIcon(threatLevelSort)}
                options={[
                  {
                    label: 'Acs',
                    value: 'acs',
                    icon: (
                      <ArrowUp
                        className={'w-4 text-muted-foreground'}
                        strokeWidth={1.5}
                      />
                    )
                  },
                  {
                    label: 'Dcs',
                    value: 'dcs',
                    icon: (
                      <ArrowDown
                        className={'w-4 text-muted-foreground'}
                        strokeWidth={1.5}
                      />
                    )
                  }
                ]}
                // value={threatLevelSort}
                setSelectedValue={setThreatLevelSort}
              />
            </TableHead>
          )}
        </TableRow>
      </TableHeader>
      <TableBody className='w-full'>
        {sortedData()?.length > 0 ? (
          sortedData().map((item, index) => (
            <TableRow
              onClick={() => {
                if (selectedTab === 'impersonations') {
                  const encodedId = encodeURIComponent(item.username_platform)
                  handleNavigation(
                    `${encodedId}?type=username_platform&takedown=${!!item.takedown_id}&selectedTab=accounts`
                  )
                } else {
                  handleNavigation(
                    `${item.takedown_id ? item.takedown_id : item.search_results_id}?takedown=${item.takedown_id ? 'true&selectedTab=media' : false}`
                  )
                }
              }}
              key={index}
              className='max-h-[52px] cursor-pointer'
            >
              <TableCell
                className='text-foreground text-sm font-normal w-10 truncate py-2'
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Checkbox
                  id={item?.search_results_id}
                  onCheckedChange={(e) =>
                    handleSelect({
                      checked: e,
                      id:
                        selectedTab === 'impersonations'
                          ? item.username_platform
                          : item.search_results_id
                    })
                  }
                  disabled={
                    !(
                      (selectedTab === 'impersonations' &&
                        (item.category === 'instagram' ||
                          item.category === 'facebook')) ||
                      (selectedTab === 'all' && item.category === 'adult') ||
                      selectedTab === 'adult' ||
                      selectedTab === 'deepfake'
                    )
                  }
                  checked={selectedResults.includes(
                    selectedTab === 'impersonations'
                      ? item.username_platform
                      : item.search_results_id
                  )}
                />
              </TableCell>
              {selectedColumns?.includes('result') && (
                <TableCell className='text-foreground text-sm font-normal max-w-72 truncate py-2'>
                  <div className='w-full flex items-center gap-6'>
                    <div className='max-w-[90%] truncate flex items-center gap-6'>
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <NsfwImageSection
                          hideSensitiveContent={hideSensitiveContent}
                          img={item.face_url || item.profile_image}
                          intermediateFallbackImg={item.image_url}
                          imgFallback={item.frame_url}
                          size='small'
                          category={item.category}
                        />
                      </div>
                      <p className='truncate'>{item.title || 'Unavailable'}</p>
                    </div>
                    {item.review_required && (
                      <div className='flex text-yellow-600 bg-yellow-200/60 items-center w-fit px-3 py-1 rounded-full'>
                        <RequiresReviewIcon />
                        <p className='text-xs font-medium'>Requires Review</p>
                      </div>
                    )}
                  </div>
                </TableCell>
              )}
              {selectedColumns?.includes('followers') && (
                <TableCell className='text-sm font-medium max-w-36 truncate py-3'>
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(item.page_url, '_blank')
                    }}
                    className='w-fit py-1'
                  >
                    {item?.followers?.toLocaleString('en-us') ?? 'Unavailable'}
                  </p>
                </TableCell>
              )}
              {selectedColumns?.includes('following') && (
                <TableCell className='text-sm font-medium max-w-36 truncate py-3'>
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(item.page_url, '_blank')
                    }}
                    className='w-fit py-1'
                  >
                    {item?.following?.toLocaleString('en-us') ?? 'Unavailable'}
                  </p>
                </TableCell>
              )}
              {selectedColumns?.includes('source') && (
                <TableCell className='text-sm font-medium max-w-36 truncate py-3'>
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(item.page_url, '_blank')
                    }}
                    className='w-fit py-1'
                  >
                    {item?.page_url ? (
                      <TooltipWrapper
                        text={
                          <div className='flex items-center gap-2 hover:text-green-500'>
                            {<SquareArrowOutUpRight className='w-4 h-4' />}
                            <span className='text-xs'>{item?.page_url}</span>
                          </div>
                        }
                        component={
                          <div className='flex items-center justify-start gap-2'>
                            <SocialIcon platform={item?.category} />
                            <span>
                              {item.username
                                ? `@${item.username}`
                                : item?.domain || 'Unavailable'}
                            </span>
                          </div>
                        }
                        delayDuration={20}
                      />
                    ) : null}
                  </p>
                </TableCell>
              )}
              {selectedColumns?.includes('threat_level') && (
                <TableCell className='text-foreground text-sm font-normal py-3'>
                  <div className='flex items-center'>
                    {selectedTab === 'impersonations'
                      ? getThreatLevel(item?.overall_score)
                      : getThreatLevel(item?.threat_level)}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan='10'
              className='text-center text-foreground text-sm font-normal py-3'
            >
              No data available...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
