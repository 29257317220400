import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger
} from '../common/ui/dialog'
import { Button } from '../common/ui/button'
import { CircleHelpIcon } from 'lucide-react'
import LabelWrapper from '../common/Wrappers/Label/LabelWrapper'
import InputWrapper from '../common/Wrappers/Input/InputWrapper'
import { Textarea } from '../common/ui/textarea'
import { useFormspark } from '@formspark/use-formspark'
import globalStore from '@/zustand/globalStore'
import { useStore } from 'zustand'
import { toast } from 'sonner'
import ButtonWrapper from '../common/Wrappers/Button/ButtonWrapper'

const initialState = {
  name: '',
  message: '',
  email: ''
}

export default function Support({ isOpen, toggle }) {
  const [submit, submitting] = useFormspark({
    formId: 'zZuIZGj3S'
  })
  const { userData } = useStore(globalStore)
  const [formData, setFormData] = useState(initialState)
  const [error, setError] = useState(initialState)

  useEffect(() => {
    if (userData?.email_address && formData.email === '') {
      setFormData((prevState) => ({
        ...prevState,
        email: userData.email_address
      }))
    }
  }, [userData, formData.email])

  const handleChange = (key, value) => {
    if (key in error) {
      setError((prevState) => ({
        ...prevState,
        [key]: ''
      }))
    }

    setFormData((prev) => ({ ...prev, [key]: value }))
  }

  const validateForm = () => {
    let hasError = {}

    if (formData.name === '') {
      hasError = {
        ...hasError,
        name: 'Name is required'
      }
    }

    if (formData.message === '') {
      hasError = {
        ...hasError,
        message: 'Query is required'
      }
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      hasError = {
        ...hasError,
        email: 'Email is invalid. Please check again.'
      }
    }

    if (formData.email === '') {
      hasError = {
        ...hasError,
        email: 'Email is required'
      }
    }

    if (Object.keys(hasError).length > 0) {
      setError({
        ...initialState,
        ...hasError
      })
      return false
    } else {
      return true
    }
  }

  const handleSubmit = async () => {
    setError(initialState)

    if (validateForm()) {
      try {
        const response = await submit(formData)

        if (response) {
          setFormData(initialState)
          setError(initialState)
          toast('We have received your request!', {
            description: 'We will be in touch with you shortly.'
          })
          toggle()
        }
      } catch (err) {
        toast('Something went wrong!', {
          description: 'Please try again.'
        })
      }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={toggle}>
      <DialogTrigger>
        <Button
          variant='outline'
          size='icon'
          className='rounded-full h-[28px] w-[28px] p-1'
          onClick={toggle}
        >
          <CircleHelpIcon className='w-4 h-4' />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Contact Support</DialogTitle>

          <DialogDescription className='flex flex-col gap-1'>
            <span>Let us know how we can help you today.</span>
            <span>Support hours: 8:00am - 18:00pm PST.</span>
          </DialogDescription>
        </DialogHeader>

        <div className='flex flex-col gap-4 pt-2'>
          <div className='flex flex-col'>
            <LabelWrapper
              text='Name'
              htmlFor='name'
              className={`font-medium pb-3 ${error?.name ? 'text-red-600' : ''}`}
            />
            <InputWrapper
              type='text'
              placeholder='Your name'
              className='font-normal'
              value={formData.name}
              disabled={submitting}
              onChange={(e) => {
                handleChange('name', e.target.value)
              }}
            />
            <div className='text-red-600 px-1 text-sm'>{error?.name}</div>
          </div>

          {!userData?.email_address ? (
            <div className='flex flex-col'>
              <LabelWrapper
                text='Email Address'
                htmlFor='email'
                className={`font-medium pb-3 ${error?.email ? 'text-red-600' : ''}`}
              />
              <InputWrapper
                type='email'
                placeholder='Your email address'
                className='font-normal'
                value={formData.email}
                disabled={submitting}
                onChange={(e) => {
                  handleChange('email', e.target.value)
                }}
              />
              <div className='text-red-600 px-1 text-sm'>{error?.email}</div>
            </div>
          ) : null}

          <div className='flex flex-col space-y-1.5'>
            <LabelWrapper
              text='What can we help you with?'
              htmlFor='message'
              className={`font-medium ${error?.message ? 'text-red-600' : ''}`}
            />
            <div className='relative'>
              <Textarea
                className='font-normal resize-none h-32'
                placeholder='Your message'
                value={formData.message}
                disabled={submitting}
                onChange={(e) => {
                  handleChange('message', e.target.value)
                }}
              />
            </div>

            <div className='text-red-600 px-1 text-sm'>{error?.message}</div>
          </div>

          <div className='flex items-center justify-between'>
            <span className='text-sm max-w-40'>
              You can also email us at{' '}
              <a
                href='mailto:support@goloti.com'
                target='_blank'
                className='font-medium font-xs hover:text-green-500'
              >
                support@goloti.com
              </a>
            </span>

            <ButtonWrapper
              text='Send Message'
              onClick={handleSubmit}
              loading={submitting}
              disabled={submitting}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
