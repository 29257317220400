import { useEffect } from 'react'

export const useCheckResultType = ({
  setDisableTakedownButton,
  selectedResults,
  watchtowerData
}) => {
  useEffect(() => {
    const checkAdultResults = () => {
      const isNonAdult = selectedResults.some((id) => {
        const result = watchtowerData?.deepfake?.data?.find(
          (item) => item?.search_results_id === id
        )
        return result && result?.category !== 'adult'
      })
      setDisableTakedownButton(isNonAdult)
    }

    checkAdultResults()
  }, [selectedResults, watchtowerData.deepfake.data])
}
