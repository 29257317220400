import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/common/ui/dialog'
import { saveAsset } from '@/redux/Assets/actions'
import { Loader2, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function ImageUploadDialog({ open, setOpen, loading, faces }) {
  const dispatch = useDispatch()
  const [selectedFace, setSelectedFace] = useState(null)

  const handleSelect = (face) => {
    setSelectedFace(face)
  }
  const handleSave = () => {
    dispatch(
      saveAsset({ payload: { asset_id: selectedFace, onboarding: false } })
    )
  }

  useEffect(() => {
    faces?.results?.length === 1 && setSelectedFace(faces?.results[0]?.asset_id)
  }, [faces?.results, setSelectedFace])

  return (
    <Dialog open={open} onOpenChange={setOpen} modal={true}>
      <DialogContent className='sm:max-w-[40vw]'>
        <div className='absolute right-2 top-2'>
          <X />
        </div>
        <DialogHeader>
          <DialogTitle>Upload Asset</DialogTitle>
          {faces && (
            <DialogDescription className='py-1'>
              <div>Is this the correct person?</div>
              <div className='pt-1'>
                Confirm that this photo matches the person in the image you
                provided.
              </div>
            </DialogDescription>
          )}
        </DialogHeader>
        {loading && (
          <div className='flex gap-4'>
            <div>
              <p>Please wait while we&apos;re uploading the image...</p>
            </div>
            <div className=''>
              <Loader2 className='animate-spin' />
            </div>
          </div>
        )}
        {faces && (
          <>
            {faces?.results?.map((item) => (
              <div
                key={item.asset_id}
                className={`flex cursor-pointer w-fit h-fit rounded-sm ${selectedFace === item.asset_id && ' ring ring-green-500 ring-offset-2 rounded-sm'}`}
                onClick={() => {
                  handleSelect(item.asset_id)
                }}
              >
                <div className=''>
                  <img src={item.face_url} alt='Image' />
                </div>
              </div>
            ))}
          </>
        )}
        <DialogFooter>
          <ButtonWrapper
            text='Cancel'
            variant='outline'
            onClick={() => {
              setOpen(false)
            }}
            disabled={loading && true}
          />
          <ButtonWrapper
            text='Save'
            onClick={handleSave}
            disabled={!selectedFace && true}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
