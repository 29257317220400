import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/common/ui/table.jsx'
import TakedownCustomBadge from '@/pages/Takedowns/pages/Home/components/TakedownCustomBadge.jsx'
import { formatLastModified, getCategoryFromIndexType } from '@/lib/utils.js'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage.jsx'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore.js'
import takedownStore from '@/pages/Takedowns/takedownStore.js'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import { SquareArrowOutUpRight } from 'lucide-react'
import SocialIcon from '@/components/common/ui/social-icon'

export default function TakedownTable({ selectedColumns, data }) {
  const navigate = useNavigate()
  const { selectedTab } = useStore(takedownStore)
  const handleNavigation = (id) => {
    navigate(`/takedown/${id}?selectedTab=${selectedTab}`)
  }
  const { hideSensitiveContent } = useStore(globalStore)
  return (
    <Table className='h-full w-full border-b'>
      <TableHeader className='h-full bg-gray-100 hover:bg-muted/50'>
        <TableRow className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
          {selectedColumns?.includes('user_name') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              User
            </TableHead>
          )}
          {selectedColumns?.includes('title') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Title
            </TableHead>
          )}
          {selectedColumns?.includes('source') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Source
            </TableHead>
          )}
          {selectedColumns?.includes('status') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Status
            </TableHead>
          )}
          {selectedColumns?.includes('last_modified') && (
            <TableHead className='text-muted-foreground text-xs font-medium h-5 pb-2 pt-2'>
              Last modified
            </TableHead>
          )}
        </TableRow>
      </TableHeader>
      <TableBody className='w-full'>
        {data?.length >= 1 ? (
          data?.map((item) => (
            <TableRow
              key={item.id}
              className='max-h-[52px] cursor-pointer'
              onClick={() => {
                handleNavigation(
                  selectedTab === 'media' ? item.takedown_id : item.id
                )
              }}
            >
              {selectedColumns?.includes('user_name') && (
                <TableCell className='text-foreground text-sm font-normal max-w-72 truncate py-3 h-20'>
                  <div className='h-full truncate w-full flex items-center gap-4'>
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <NsfwImageSection
                        hideSensitiveContent={false}
                        img={item.profile_image_url || ''}
                        size='small'
                        category={item.category}
                        handleNavigation={() => {
                          handleNavigation(item.id)
                        }}
                      />
                    </div>
                    <p className={'truncate'}>
                      {item?.platform_username || 'Unavailable'}
                    </p>
                  </div>
                </TableCell>
              )}
              {selectedColumns?.includes('title') && (
                <TableCell className='text-foreground text-sm font-normal max-w-72 truncate py-3 h-20'>
                  <div className='h-full truncate w-full flex items-center gap-4'>
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <NsfwImageSection
                        hideSensitiveContent={hideSensitiveContent}
                        img={item.face_url || ''}
                        size='small'
                        category={getCategoryFromIndexType(item?.index_type)}
                        handleNavigation={() => {
                          handleNavigation(item.id)
                        }}
                      />
                    </div>
                    <p className={'truncate'}>{item?.title || 'Unavailable'}</p>
                  </div>
                </TableCell>
              )}
              {selectedColumns?.includes('source') && (
                <TableCell
                  className='text-foreground text-sm font-medium max-w-72 truncate py-3 h-20'
                  onClick={(e) => {
                    if (item?.page_url) {
                      e.stopPropagation()
                      window.open(item?.page_url, '_blank')
                    }
                  }}
                >
                  {(() => {
                    if (item?.page_url) {
                      return (
                        <TooltipWrapper
                          text={
                            <div className='flex items-center gap-2 hover:text-green-500'>
                              {<SquareArrowOutUpRight className='w-4 h-4' />}
                              <span className='text-xs'>{item?.page_url}</span>
                            </div>
                          }
                          component={
                            <div className='flex items-center justify-start gap-2'>
                              <SocialIcon
                                platform={_.upperFirst(
                                  getCategoryFromIndexType(
                                    item?.index_type || 'Unavailable'
                                  )
                                )}
                              />
                              <span>
                                {item.username
                                  ? `@${item.username}`
                                  : item?.domain || 'Unavailable'}
                              </span>
                            </div>
                          }
                          delayDuration={20}
                        />
                      )
                    }

                    if (item?.platform_name) {
                      return (
                        <div className='flex items-center justify-start gap-2'>
                          <SocialIcon platform={item?.platform_name} />
                          <span>
                            {item.platform_username
                              ? `@${item.platform_username}`
                              : 'Unavailable'}
                          </span>
                        </div>
                      )
                    }
                    return null
                  })()}
                </TableCell>
              )}
              {selectedColumns?.includes('status') && (
                <TableCell className='text-foreground text-sm font-medium max-w-72 truncate py-3 h-20'>
                  <TakedownCustomBadge text={item?.status} />
                </TableCell>
              )}
              {selectedColumns?.includes('last_modified') && (
                <TableCell className='text-foreground text-sm font-medium max-w-72 truncate py-3 h-20'>
                  <div>
                    {formatLastModified(item.updatedAt || item.updated_at)}
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan='5'
              className='text-center text-foreground text-sm font-normal py-3'
            >
              No data available...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
