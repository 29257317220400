import { useEffect } from 'react'
import { clearAllZustandStores } from '@/lib/utils.js'
import { logoutAction } from '@/redux/Auth/actions.js'
import { clearErrorAction } from '@/redux/User/actions.js'

export const useLogout = ({ tokenExpired, cross_token, dispatch }) => {
  useEffect(() => {
    if (tokenExpired && !cross_token) {
      clearAllZustandStores()
      dispatch(logoutAction())
      dispatch(clearErrorAction())
    }
  }, [tokenExpired, dispatch])
}
