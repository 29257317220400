import { Play } from 'lucide-react'

export default function AudioContainer() {
  return (
    <div className='p-4 rounded-md border'>
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center gap-4'>
          <div className='rounded-full py-1 px-2 bg-gray-100'>
            <Play className='w-4' strokeWidth={1.5} />
          </div>
          <div className='font-semibold text-sm'>
            <p>Podcast Episode 01</p>
          </div>
        </div>
        <div className='text-muted-foreground text-xs'>
          <p>3:45</p>
        </div>
      </div>
      <div className='h-2 bg-gray-100 w-full my-4 rounded-md'></div>
      <div className='w-full flex justify-between'>
        <div className='text-muted-foreground text-xs'>
          <p>3:45</p>
        </div>
        <div className='text-muted-foreground text-xs'>
          <p>3:45</p>
        </div>
      </div>
    </div>
  )
}
