import SocialIcon from '@/components/common/ui/social-icon'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import { formatLastModified } from '@/lib/utils'

export default function InfoSection({ data }) {
  return (
    <>
      <div className='flex flex-wrap w-full py-3 gap-6'>
        <div className=''>
          <div className=''>
            <div className=' py-1 text-sm text-muted-foreground'>
              <p>Last modified</p>
            </div>
            <div className='text-sm text-foreground'>
              <p>{formatLastModified(data.lastUpdated)}</p>
            </div>
          </div>
        </div>
        <div className='max-w-[20rem] '>
          <div className=''>
            <div className='py-1 text-sm text-muted-foreground'>
              <p>Source</p>
            </div>
            <div className='text-sm font-medium text-green-600 cursor-pointer'>
              <a
                className='flex gap-2 items-center '
                href={data.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='flex items-center'>
                  {data?.category ? (
                    <TooltipWrapper
                      component={
                        <SocialIcon
                          className='w-4 h-4'
                          platform={data?.category}
                        />
                      }
                      text={
                        data?.category === 'adult'
                          ? 'Sensitive Content'
                          : data?.category.charAt(0).toUpperCase() +
                            data?.category.slice(1)
                      }
                      delayDuration={20}
                    />
                  ) : null}
                </div>

                <p className='truncate'>{data.url || 'Unavailable'}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
