import globalStore from '@/zustand/globalStore'
import * as actionTypes from './actionTypes'

const initialState = {
  error: false,
  loading: false,
  auth: null,
  errorMessage: ''
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUESTED: {
      globalStore.getState().setLoading(true)
      globalStore.getState().setTokenExpired(false)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.LOGIN_SUCCESS: {
      globalStore.getState().setAuth(action.payload)
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        auth: action.payload
      }
    }
    case actionTypes.LOGIN_FAILURE: {
      globalStore.getState().setAuth(null)
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        auth: null,
        errorMessage: action.payload
      }
    }
    case actionTypes.LOGOUT: {
      globalStore.getState().clear()
      return {
        ...state,
        error: false,
        errorMessage: '',
        auth: null
      }
    }
    case actionTypes.CROSS_LOGIN: {
      globalStore.getState().setLoading(true)
      globalStore.getState().setTokenExpired(false)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    }
    case actionTypes.CROSS_LOGIN_SUCCESS: {
      globalStore.getState().setAuth(action.payload)
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        userData: action.payload
      }
    }
    case actionTypes.CROSS_LOGIN_FAILURE: {
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
        userData: null
      }
    }
    default:
      return state
  }
}

export default authReducer
