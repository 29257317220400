import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { ScrollBar, ScrollArea } from '../common/ui/scroll-area'

export default function Pagination({
  currentPage,
  disableNext,
  disablePrev,
  handlePageChange,
  pageKeys,
  loading
}) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1)
    }
  }
  const handleNext = () => {
    handlePageChange(currentPage + 1)
  }
  return (
    <div className='flex justify-end bg-background w-full border-t py-2'>
      <ScrollArea className='w-full py-2 flex justify-end'>
        <div className='flex items-center justify-end'>
          <div className='pr-1 flex items-center justify-center'>
            <ButtonWrapper
              variant='outline'
              size='sm'
              text='Previous'
              onClick={handlePrevious}
              disabled={disablePrev || loading}
            />
          </div>
          {pageKeys.length === 0 && (
            <ButtonWrapper
              variant='outline'
              size='sm'
              className={`h-9 w-[40px] mx-1 bg-primary text-white hover:bg-primary hover:text-white `}
              onClick={() => handlePageChange(1)}
              text='1'
              disabled={true}
            />
          )}
          {pageKeys.map((_, index) => (
            <ButtonWrapper
              key={index}
              variant='outline'
              size='sm'
              className={`h-9 w-[40px] mx-1 ${
                currentPage === index + 1 ? 'bg-primary text-white' : ''
              }`}
              onClick={() => handlePageChange(index + 1)}
              text={`${index + 1}`}
            />
          ))}
          <div className='pr-1 px-1 flex items-center justify-center'>
            <ButtonWrapper
              variant='outline'
              size='sm'
              text='Next'
              onClick={handleNext}
              disabled={disableNext || loading}
            />
          </div>
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  )
}
