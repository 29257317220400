import { Tabs, TabsList, TabsTrigger } from '@/components/common/ui/tabs'
import { Avatar, AvatarFallback } from '@/components/common/ui/avatar'
import HomeIcon from '@/assets/icons/HomeIcon'
import ProfileIcon from '@/assets/icons/ProfileIcon'
import globalStore from '@/zustand/globalStore'
import { useDispatch } from 'react-redux'
import { useMemo, useState } from 'react'
import { useStore } from 'zustand'
import { Link, useLocation } from 'react-router-dom'
import { cn, getInitials, stringToColor } from '@/lib/utils'
import TakedownIcon from '@/assets/icons/TakedownIcon'
import WatchtowerIcon from '@/assets/icons/WatchtowerIcon'
import { Switch } from '../common/ui/switch'
import { ComboBox } from '@/components/SideNav/Delegation/components/ComboBox.jsx'
import { CommandBox } from '@/components/SideNav/Delegation/components/CommandBox.jsx'
import { Menu, X } from 'lucide-react'
import { useLogout } from '@/components/SideNav/hooks/useLogout.js'
import { useSetSelectedTab } from '@/components/SideNav/hooks/useSetSelectedTab.js'
import { useFetchUserData } from '@/components/SideNav/hooks/useFetchUserData.js'
import { useHandleWindowWidth } from '@/components/SideNav/hooks/useHandleWindowWidth.js'
import Support from '../Support/Support'

export function SideNav() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)
  const [isSupportOpen, setIsSupportOpen] = useState(false)
  const [isLg, setIsLg] = useState(true)
  const {
    setSelectedTab,
    selectedTab,
    auth,
    tokenExpired,
    userMetaData,
    cross_token,
    setHideSensitiveContent,
    hideSensitiveContent,
    delegates
  } = useStore(globalStore)

  const initials = getInitials(userMetaData?.full_name)
  const avatarBg = stringToColor(userMetaData?.full_name)

  const tabsList = useMemo(() => {
    return [
      {
        id: '1',
        name: 'Home',
        value: 'home',
        icon: <HomeIcon />,
        link: '/'
      },
      {
        id: '2',
        name: 'Watchtower',
        value: 'watchtower',
        icon: <WatchtowerIcon />,
        link: '/watchtower'
      },
      {
        id: '3',
        name: 'Takedowns',
        value: 'takedown',
        icon: <TakedownIcon />,
        link: '/takedown'
      },
      {
        id: '5',
        name: 'Profile',
        value: 'profile',
        icon: <ProfileIcon />,
        link: '/profile'
      }
    ]
  }, [])

  useLogout({ tokenExpired, cross_token, dispatch })
  useSetSelectedTab({ setSelectedTab, tabsList })
  useFetchUserData({ auth, dispatch })
  useHandleWindowWidth({ setIsLg, setIsSidebarExpanded })

  const handleSidebarToggle = () => {
    setIsSidebarExpanded(!isSidebarExpanded)
  }

  const handleTabChange = () => {
    if (!isLg) {
      setIsSidebarExpanded(false)
    }
  }

  const handleSupportToggle = () => {
    setIsSupportOpen(!isSupportOpen)
  }

  return (
    <>
      <div
        className={cn(
          'hidden cursor-pointer p-2 ml-2 mt-1 hover:bg-slate-950/5 rounded-md w-10 lg:w-auto',
          {
            block: !isLg,
            'bg-slate-950/5': isSidebarExpanded
          }
        )}
        onClick={handleSidebarToggle}
      >
        <Menu className='w-6' />
      </div>

      <Tabs
        defaultValue={selectedTab}
        orientation='vertical'
        className={cn('h-dvh z-50 ml-0 w-56 transition-all', {
          'fixed p-4': !isLg,
          'ml-[-100%]': !isSidebarExpanded
        })}
        value={selectedTab}
      >
        <TabsList
          className={cn(
            'flex flex-col p-0 h-dvh justify-between gap-4 bg-sidebar z-50 transition-all ease-in-out fixed rounded-none',
            {
              'h-[calc(100%-2rem)] rounded-lg': !isLg,
              'border-r border-border': isLg
            }
          )}
        >
          <div
            className={cn(
              'bg-sidebar rounded-sm border border-border items-center justify-center hidden absolute right-[-38px] h-8 w-8 cursor-pointer',
              {
                flex: !isLg
              }
            )}
            onClick={handleSidebarToggle}
          >
            <X className='w-4 text-foreground' />
          </div>

          <div className='flex flex-col w-full gap-4'>
            <div className='py-2 px-2 border-b flex items-center mb-4'>
              <ComboBox />
              {delegates?.length > 0 && <CommandBox />}
            </div>

            <div className='w-full flex flex-col gap-1 px-3 '>
              {tabsList.map((tab) => (
                <div key={tab.id}>
                  <Link to={tab.link}>
                    <TabsTrigger
                      className={`w-full flex flex-row justify-start py-1.5 px-2 gap-2 items-center border-0 data-[state=active]:border-0 data-[state=active]:shadow-sm rounded-md text-text-foreground${
                        location.pathname === tab.link
                          ? 'data-[state=active]:bg-background'
                          : ''
                      } leading-5`}
                      onClick={handleTabChange}
                      value={tab.value}
                    >
                      <p className='text-text-accent-foreground mb-[2px]'>
                        {tab.icon}
                      </p>
                      <p className='font-medium'>{tab.name}</p>
                    </TabsTrigger>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div className='w-full border-t'>
            <div
              className='text-foreground text-sm pb-2 pt-4 font-medium gap-2 items-center px-3 flex cursor-default'
              onClick={() => setHideSensitiveContent(!hideSensitiveContent)}
            >
              <Switch
                checked={hideSensitiveContent}
                onCheckedChange={() =>
                  setHideSensitiveContent(!hideSensitiveContent)
                }
                variant='xs'
              />
              <p>Hide sensitive content</p>
            </div>

            <div className='flex items-center w-full py-1 pr-3 pl-1'>
              <div className=''>
                <Avatar className='p-2'>
                  <AvatarFallback
                    className={`text-white font-medium flex items-center justify-center text-[10px]`}
                    style={{ backgroundColor: `${avatarBg}` }}
                  >
                    {initials && initials}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className='flex items-center justify-between truncate text-sm font-medium text-foreground w-full'>
                <p>{userMetaData?.full_name}</p>
                <Support isOpen={isSupportOpen} toggle={handleSupportToggle} />
              </div>
            </div>
          </div>
        </TabsList>
      </Tabs>

      <div
        className={cn(
          'fixed top-0 left-0 w-screen h-screen z-10 bg-slate-950/20 hidden',
          {
            block: isSidebarExpanded && !isLg
          }
        )}
        onClick={handleSidebarToggle}
      />
    </>
  )
}
