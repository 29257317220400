import { all } from 'redux-saga/effects'
import authSaga from './Auth/saga'
import {} from './sagas'
import userSaga from './User/saga'
import takedownsSaga from './Takedowns/saga'
import assetsSaga from './Assets/saga'
import dashboardSaga from './Dashboard/saga'
import watchtowerSaga from './Watchtower/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    takedownsSaga(),
    assetsSaga(),
    dashboardSaga(),
    watchtowerSaga()
  ])
}
