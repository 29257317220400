import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/common/ui/card'
import PropTypes from 'prop-types'
export function CardWrapper({ title, description, fields, cardFooter }) {
  return (
    <Card className='w-full border-0'>
      <CardHeader className='py-0'>
        <CardTitle className='font-medium pb-1'>{title && title}</CardTitle>
        <CardDescription className='font-normal'>
          {description && description}
        </CardDescription>
      </CardHeader>
      <div className='border pt-6 rounded-lg'>
        <CardContent>
          <form>
            <div className='grid w-full items-center gap-2'>{fields}</div>
          </form>
        </CardContent>
        <CardFooter className='flex justify-between'>{cardFooter}</CardFooter>
      </div>
    </Card>
  )
}
CardWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fields: PropTypes.node.isRequired,
  cardFooter: PropTypes.node.isRequired
}
