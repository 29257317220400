export default function TabCard({ type, content = null }) {
  return (
    <div className='cursor-pointer rounded-lg w-full py-3 flex items-start px-4 flex-col justify-center shadow-sm'>
      <div className='py-1'>
        <p className='text-sm text-muted-foreground font-normal'>{type}</p>
      </div>
      <div className='py-1 w-full text-left'>{content}</div>
    </div>
  )
}
