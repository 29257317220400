export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_REQUESTED = 'GET_USER_DATA_REQUESTED'
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE'

export const FETCH_DELEGATES = 'FETCH_DELEGATES'
export const FETCH_DELEGATES_SUCCESS = 'FETCH_DELEGATES_SUCCESS'
export const FETCH_DELEGATES_FAILURE = 'FETCH_DELEGATES_FAILURE'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE'
