import { Skeleton } from '../../ui/skeleton'

export default function SkeletonWrapper({ number, height, width, className }) {
  const skeletonArray = Array.from({ length: number })
  return (
    <div className={className}>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          style={{ height: height, width: width, marginBottom: '0.5rem' }}
        />
      ))}
    </div>
  )
}
