import { useEffect, useState } from 'react'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/common/ui/command.jsx'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore.js'
import { Avatar, AvatarFallback } from '@/components/common/ui/avatar.jsx'
import { cn, getInitials, stringToColor } from '@/lib/utils.js'
import Loti from '@/assets/loti.svg'

const useSetParentDelegate = ({
  userData,
  parentDelegate,
  setParentDelegate
}) => {
  useEffect(() => {
    if (
      userData &&
      (!parentDelegate || Object.keys(parentDelegate).length === 0)
    ) {
      setParentDelegate({
        email_address: userData?.email_address,
        full_name: userData?.full_name,
        id: userData?.id
      })
    }
  }, [])
}

const useKeyboardShortcut = ({ setOpen }) => {
  useEffect(() => {
    const down = (e) => {
      if (e.key === 'W' && e.shiftKey) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])
}

export function CommandBox({ value }) {
  const [open, setOpen] = useState(false)
  const {
    delegates,
    setDelegate,
    delegate,
    parentDelegate,
    setParentDelegate,
    userData
  } = useStore(globalStore)
  useEffect(() => {
    if (value) {
      setOpen(value)
    }
  }, [value])

  useSetParentDelegate({ userData, parentDelegate, setParentDelegate })
  useKeyboardShortcut({ setOpen })

  const handleDelegate = (delegatee) => {
    if (delegate === null) {
      if (delegatee === parentDelegate.id) {
        return
      }
      if (delegatee) {
        setDelegate(delegatee)
        setOpen(false)
        window.location.reload()
      }
    } else if (delegatee === delegate) {
      return null
    } else if (delegatee === parentDelegate.id) {
      setDelegate(null)
      setOpen(false)
      window.location.reload()
    } else {
      setDelegate(delegatee)
      setOpen(false)
      window.location.reload()
    }
  }
  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput placeholder='Search for an organisation or person...' />
      <CommandList>
        <CommandEmpty className={'border  '}>No results found.</CommandEmpty>
        <CommandGroup>
          <div className='flex items-center justify-between w-full px-2.5 h-[3rem]'>
            <div className='w-full gap-1 flex items-center py-[5px] '>
              <div className='px-2 py-2.5'>
                <img src={Loti} alt='Loti' className='w-5 h-5' />
              </div>
              <div className='font-medium text-sm text-foreground -ml-1'>
                <p>Loti</p>
              </div>
            </div>
          </div>
          {!parentDelegate && !delegates && (
            <div
              className={
                'py-2 text-sm text-muted-foreground w-full flex justify-center items-center'
              }
            >
              <p>No delegates found.</p>
            </div>
          )}
          <CommandItem
            className={'hover:cursor-pointer h-[3rem]'}
            disabled={parentDelegate?.id && !delegate}
          >
            <div
              className='flex items-center w-full'
              onClick={() => {
                handleDelegate(parentDelegate.id)
              }}
            >
              <div className=''>
                <Avatar className='p-2'>
                  <AvatarFallback
                    className={`text-white font-medium flex items-center justify-center text-[10px]`}
                    style={{
                      backgroundColor: `${stringToColor(parentDelegate?.full_name)}`
                    }}
                  >
                    {getInitials(parentDelegate?.full_name)}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className='truncate text-sm font-medium text-foreground'>
                <p>{parentDelegate?.full_name}</p>
              </div>
            </div>
          </CommandItem>
          {delegates?.map((delegatee, index) => (
            <CommandItem
              className={cn('hover:cursor-pointer h-[3rem]')}
              key={index}
              disabled={delegatee?.id === delegate}
            >
              <div
                className='flex items-center w-full'
                onClick={() => {
                  handleDelegate(delegatee?.id)
                }}
              >
                <div className=''>
                  <Avatar className='p-2'>
                    <AvatarFallback
                      className={`text-white font-medium flex items-center justify-center text-[10px]`}
                      style={{
                        backgroundColor: `${stringToColor(delegatee?.full_name)}`
                      }}
                    >
                      {getInitials(delegatee?.full_name)}
                    </AvatarFallback>
                  </Avatar>
                </div>
                <div
                  className={cn(
                    'truncate text-sm font-medium text-foreground',
                    { 'text-muted-foreground': delegatee.id === delegate }
                  )}
                >
                  <p>{delegatee?.full_name}</p>
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  )
}
