export default function ActivityInfoSection({ currentCard }) {
  return (
    <div className='w-full flex gap-10 py-4'>
      <div className=''>
        <div className='text-sm text-muted-foreground'>
          <p>Client</p>
        </div>
        <div className='text-sm py-1'>{currentCard?.text}</div>
      </div>
      <div className=''>
        <div className='text-sm text-muted-foreground'>
          <p>Category</p>
        </div>
        <div className='py-1 text-sm text-foreground'>
          <div className='flex items-center'>
            <div className='text-sm pb-[1px]'>
              <p>Lifestyle</p>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='text-sm text-muted-foreground'>
          <p>Last Modified</p>
        </div>
        <div className='py-1 text-sm text-foreground'>
          <p>15 Sept</p>
        </div>
      </div>
      <div className=''>
        <div className='text-sm text-muted-foreground'>
          <p>Expiry</p>
        </div>
        <div className='py-1 text-sm text-foreground'>
          <div className='flex items-center'>
            <div className='text-sm pb-[1px]'>
              <p>01/01/2025</p>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='text-sm text-muted-foreground'>
          <p>Performance</p>
        </div>
        <div className='py-1 text-sm text-foreground'>
          <div className='flex items-center'>
            <div className='text-sm pb-[1px]'>
              <p>Human</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
