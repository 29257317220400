import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
const initialTabState = {
  lastKey: null,
  pageKeys: {},
  currentPage: 1,
  isLastPage: false,
  data: null,
  appliedFilters: {},
  appliedSort: {}
}
const watchtowerDataInitialState = {
  all: { ...initialTabState },
  review_required: { ...initialTabState },
  adult: { ...initialTabState },
  instagram: { ...initialTabState },
  impersonations: { ...initialTabState },
  social: { ...initialTabState },
  deepfake: { ...initialTabState }
}
const watchtowerStore = create(
  devtools((set) => ({
    watchtowerData: watchtowerDataInitialState,
    setWatchtowerData: (tab, data) =>
      set((state) => ({
        watchtowerData: {
          ...state.watchtowerData,
          [tab]: {
            ...state.watchtowerData[tab],
            ...data
          }
        }
      })),

    reviewRequiredData: watchtowerDataInitialState,
    setReviewRequiredData: (tab, data) =>
      set((state) => ({
        reviewRequiredData: {
          ...state.reviewRequiredData,
          [tab]: {
            ...state.reviewRequiredData[tab],
            ...data
          }
        }
      })),

    selectedReviewRequiredTab: 'all',
    setSelectedReviewRequiredTab: (value) =>
      set({ selectedReviewRequiredTab: value }),

    selectedFilterTab: 'results',
    setSelectedFilterTab: (value) => set({ selectedFilterTab: value }),

    lastKey: null,
    setLastKey: (value) => set({ lastKey: value }),

    singleSearch: null,
    setSingleSearch: (value) => set({ singleSearch: value }),

    pageKeys: [],

    isLastPage: false,
    setIsLastPage: (value) => set({ isLastPage: value }),

    searchCounts: null,
    setSearchCounts: (value) => set({ searchCounts: value }),

    singleWatchtowerData: null,
    setSingleWatchtowerData: (value) => set({ singleWatchtowerData: value }),

    singleImpersonatorData: null,
    setSingleImpersonatorData: (value) =>
      set({ singleImpersonatorData: value }),

    searchSummary: null,
    setSearchSummary: (value) => set({ searchSummary: value }),

    markForTakedownStatus: { success: null },
    setMarkForTakedownStatus: (value) => set({ markForTakedownStatus: value }),

    selectedTab: 'all',
    setSelectedTab: (value) => set({ selectedTab: value }),

    currentPage: 1,
    setCurrentPage: (value) => set({ currentPage: value }),

    loading: false,
    setLoading: (value) => set({ loading: value }),

    resetPageKeys: () => set({ pageKeys: [] }),

    setPageKey: (dataKey, tab, page, key) =>
      set((state) => ({
        [dataKey]: {
          ...state[dataKey],
          [tab]: {
            ...state[dataKey][tab],
            pageKeys: { ...state[dataKey][tab].pageKeys, [page]: key }
          }
        }
      })),

    selectedColumns: ['result', 'source', 'category'],
    setSelectedColumns: (value) => set(() => ({ selectedColumns: value })),

    sources: [],
    setSources: (value) => set(() => ({ sources: value })),

    threatLevel: [],
    setThreatLevel: (value) => set(() => ({ threatLevel: value })),

    selectedResults: [],
    setSelectedResults: (value) => set(() => ({ selectedResults: value })),

    columns: [
      { id: 'result', label: 'Result' },
      { id: 'source', label: 'Source' },
      { id: 'category', label: 'Category' }
    ],
    markedForDelete: [],
    setMarkedForDelete: (value) => set(() => ({ markedForDelete: value })),
    tableLoading: false,
    setTableLoading: (value) => set({ tableLoading: value }),

    sortConfig: { key: null, direction: 'asc' },
    setSortConfig: (value) => set({ sortConfig: value }),

    disableTakedownButton: false,
    setDisableTakedownButton: (value) => set({ disableTakedownButton: value }),

    clear: () => {
      set({
        watchtowerData: watchtowerDataInitialState,
        lastKey: null,
        pageKeys: [],
        isLastPage: false,
        searchCounts: null,
        singleWatchtowerData: null,
        searchSummary: null,
        selectedTab: 'all',
        currentPage: 1,
        loading: false
      })
    }
  }))
)
export default watchtowerStore
