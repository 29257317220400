import * as actionTypes from './actionTypes'

export const getuserInfoAction = () => ({
  type: actionTypes.GET_USER_DATA
})

export const clearErrorAction = () => ({
  type: actionTypes.CLEAR_ERROR
})

export const fetchDelegates = () => ({
  type: actionTypes.FETCH_DELEGATES
})

export const updateUserData = ({ payload }) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload
})
