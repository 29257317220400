export default function Scans() {
  return (
    <div className=' w-80 h-40'>
      <div className='w-full h-full rounded-t-lg overflow-hidden'>
        <img
          src='https://www.goloti.dev/images/freja-2024.png'
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
      <div className='border py-4 px-6 rounded-b-lg overflow-hidden'>
        <div className='text-base font-semibold'>
          <p>Freja&apos;s scans 2024</p>
        </div>
        <div className='text-muted-foreground text-xs '>
          <p>Last edited just now</p>
        </div>
      </div>
    </div>
  )
}
