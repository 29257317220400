import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import { useStore } from 'zustand'
import globalStore from '@/zustand/globalStore'
import WelcomeBack from './components/WelcomeBack'

const WelcomeSection = ({ loading }) => {
  const { userData } = useStore(globalStore)
  return (
    <div className=''>
      {loading ? (
        <SkeletonWrapper width={'100%'} height={'3rem'} number={1} />
      ) : (
        <WelcomeBack userName={userData?.full_name} />
      )}
    </div>
  )
}

export default WelcomeSection
