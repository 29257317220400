import { useEffect } from 'react'

export const useHandleWindowWidth = ({ setIsSidebarExpanded, setIsLg }) => {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    const handleMediaQueryChange = (e) => {
      setIsSidebarExpanded(e.matches)
      setIsLg(e.matches)
    }
    handleMediaQueryChange(mediaQuery)
    mediaQuery.addEventListener('change', handleMediaQueryChange)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])
}
