export const LOGIN = 'LOGIN'
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'

export const CROSS_LOGIN = 'CROSS_LOGIN'
export const CROSS_LOGIN_SUCCESS = 'CROSS_LOGIN_SUCCESS'
export const CROSS_LOGIN_FAILURE = 'CROSS_LOGIN_FAILURE'
