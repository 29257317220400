import { useState } from 'react'
import { useStore } from 'zustand'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'
import DropdownTableFilter from '@/components/DropdownTableFilter/DropdownTableFilter'
import { useSetSelectedColumns } from '@/pages/Watchtower/hooks/useSetSelectedColumns.js'
import FiltersCommandBox from '@/pages/Takedowns/pages/Home/components/FiltersCommandBox.jsx'
import PlusIcon from '@/assets/icons/PlusIcon.jsx'

const initialAvailableColumns = [
  { label: 'Result', value: 'result' },
  { label: 'Source', value: 'source' },
  { label: 'Threat Level', value: 'threat_level' }
]
const initSelectedColumns = ['result', 'source', 'category', 'threat_level']
const impersonationsColumns = ['followers', 'following', 'threat_level']

export default function Filters() {
  const {
    selectedColumns,
    setSelectedColumns,
    selectedTab,
    threatLevel,
    setThreatLevel,
    setWatchtowerData
  } = useStore(watchtowerStore)
  const [availableColumns, setAvailableColumns] = useState(
    initialAvailableColumns
  )
  useSetSelectedColumns({
    selectedTab,
    setSelectedColumns,
    initSelectedColumns,
    impersonationsColumns,
    setAvailableColumns,
    initialAvailableColumns
  })

  const handleThreatLevelChange = (value) => {
    setThreatLevel(value)
    setWatchtowerData(selectedTab, {
      appliedFilters: { threat_level: value }
    })
  }

  return (
    <div className='py-2 flex items-center gap-2 justify-between w-full'>
      {/*<div className=''>*/}
      {/*  <FiltersCommandBox*/}
      {/*    title={'Requires Review'}*/}
      {/*    options={[*/}
      {/*      { label: 'Yes', value: true },*/}
      {/*      { label: 'No', value: false }*/}
      {/*    ]}*/}
      {/*    selectedValues={reviewRequired}*/}
      {/*    setSelectedValues={(value) => setReviewRequired(value)}*/}
      {/*    icon={<PlusIcon />}*/}
      {/*  />*/}
      {/*</div>*/}
      {/* <div className='flex gap-2'>
        <div className=''>
          <FiltersCommandBox
            title={'Source'}
            options={[
              { label: 'Successful', value: 'completed' },
              { label: 'Processing', value: 'processed' },
              { label: 'Escalated', value: 'created' }
            ]}
            selectedValues={sources}
            setSelectedValues={(value) => setSources(value)}
            icon={<PlusIcon className='mr-2 h-4 w-4' />}
          />
        </div>
        <div className=''>
          <FiltersCommandBox
            title={'Threat Level'}
            options={[
              { label: 'Low', value: 'low' },
              { label: 'Moderate', value: 'moderate' },
              { label: 'High', value: 'high' },
              { label: 'Critical', value: 'critical' }
            ]}
            selectedValues={threatLevel}
            setSelectedValues={(value) => setThreatLevel(value)}
            icon={<PlusIcon className='mr-2 h-4 w-4' />}
          />
        </div>
      </div> */}
      {/*{selectedTab === 'deepfake' && (*/}
      <div className=''>
        <FiltersCommandBox
          title={'Threat Level'}
          options={[
            { label: 'Low', value: 'low' },
            { label: 'Moderate', value: 'moderate' },
            { label: 'High', value: 'high' },
            { label: 'Critical', value: 'critical' }
          ]}
          selectedValues={threatLevel}
          setSelectedValues={(value) => handleThreatLevelChange(value)}
          icon={<PlusIcon className='mr-2 h-4 w-4' />}
          type={'singleSelect'}
        />
      </div>
      {/*// )}*/}
      <div className='w-full flex justify-end'>
        <DropdownTableFilter
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
          availableTableItems={availableColumns}
          selectedTab={selectedTab}
          useTabs={false}
        />
      </div>
    </div>
  )
}
