import globalStore from '@/zustand/globalStore'
import axios from 'axios'
import { getEncryptedUserId } from '@/lib/utils.js'

const baseURL = 'https://api-v2.goloti.com/api'
// http://192.168.1.16:1403/api
// https://api-v2.goloti.com/api
const API = axios.create({ baseURL: baseURL })

API.interceptors.request.use(async (request) => {
  const sessionToken = globalStore.getState().auth?.session_token
  const delegate = globalStore.getState().delegate

  if (delegate) {
    try {
      const encryptedDelegate = await getEncryptedUserId(delegate)
      request.headers['user-id'] = encryptedDelegate
    } catch (error) {
      console.error('Error encrypting delegate:', error)
    }
  }
  if (request.url.endsWith('/login' || '/agent-auth')) {
    request.headers['Content-Type'] = 'application/json'
  } else {
    request.headers['Authorization'] = `Bearer ${sessionToken}`
  }
  return request
})

API.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      return Promise.resolve({ data: { success: true, data: [] }, status: 204 })
    }
    return response
  },
  (error) => {
    if (
      error.response.data.statusCode === 401 ||
      error.response.status === 401
    ) {
      globalStore.getState().setTokenExpired(true)
    }
    return Promise.reject(error)
  }
)

export default API
