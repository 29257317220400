export default function AnalyticsIcon({ fill = 'currentColor', ...rest }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke={fill}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...rest}
    >
      <line x1='18' x2='18' y1='20' y2='10' />
      <line x1='12' x2='12' y1='20' y2='4' />
      <line x1='6' x2='6' y1='20' y2='14' />
    </svg>
  )
}
